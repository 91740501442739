<template>
    <div class="fixed flex items-center inset-0 overflow-hidden z-50 bg-gray-200 bottom-0">
        <div class="grid grid-cols-7 w-full gap-5 m-56">
            <div class="col-span-4  borde mt-20">
                <div class="text-5xl text-red-600 font-semibold">DWITAMA ELEKTRINDO</div>
            </div>
            <div class="col-span-3">
                <div class="bg-white p-5 border border-gray-300 rounded-sm shadow-lg">
                    <form @submit.prevent="onSubmit">
                        <div class="mb-5">
                            <input v-model="form.email" @keydown="error.email=''" :class="{'border-red-500' : error.email}" class="h-12 w-full rounded-sm px-5 text-lg border" type="email" placeholder="Email">
                            <div v-if="error.email" class="text-red-500 mt-1">{{error.email}}</div>
                        </div>
                        <div class="mb-5">
                            <input v-model="form.password" @keydown="error.password=''" :class="{'border-red-500' : error.password}" class="h-12 w-full rounded-sm px-5 text-lg border" type="password" placeholder="Password">
                            <div v-if="error.password" class="text-red-500 mt-1">{{error.password}}</div>
                        </div>
                        <div>
                            <button type="submit" class="relative h-12 bg-red-600 w-full text-lg text-gray-50 rounded-sm hover:bg-red-700 hover:bg-opacity-90" :disabled="isLoading">
                                <span>Log In</span>
                                <div v-show="isLoading" class="absolute bg-red-400 w-full h-full top-0 rounded-sm flex items-center justify-center">
                                    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </form>
                    <div class="text-center my-5">
                        <a href="#" class="text-base text-red-500 hover:text-red-600">Forgotten password?</a>
                    </div>
                    <div class="border-t"></div>
                    <div class="my-10 text-center">
                        Real time application integration
                    </div>
                </div>
                <div class="text-center mt-10">
                    <div>© 2022 DWITAMA ELEKTRINDO</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data () {
        return {
            isLoading: false,
            form: {
                email: '',
                password: ''
            },
            error: {
                email: '',
                password: ''
            }
        }
    },
    methods: { 
        ...mapActions({
            login: 'auth/login'
        }),

        onSubmit () {
            if(!this.form.email) {
                this.error.email = 'Eamil is required'
            }
            if(!this.form.password) {
                this.error.password = 'Password is required'
            }
            if(this.form.email && this.form.password) {
                this.signIn()
            }
        },

        async signIn () {
            this.isLoading = true
            await this.login(this.form)
            .then(() => {
                this.isLoading = false
                this.$router.push('/')
            })
            .catch(err => {
                this.isLoading = false
                if(err.response.data == 'Email not found') {
                    this.error.email = err.response.data
                }
                if(err.response.data == 'Wrong password') {
                    this.error.password = err.response.data
                }
            })
        }
    }
}
</script>