<template>
    <div class="pb-10">
        <form @submit.prevent="onSubmit">
            <div class="bg-white border rounded-sm p-5 mb-5">
                <div class="mb-5">
                    <h6 class="text-lg">Product Upload</h6>
                </div>
                <div class="flex items-center justify-center mx-7 mb-5">
                    <div class="w-full flex items-center space-x-5">
                        <draggable
                            :list="images"
                            :animation="200"
                            class="flex space-x-5"
                            ghost-class="border-red-600"
                            draggable=".item"
                        >
                            <div v-for="(element, i) in images" :key="i" class="relative w-40 h-40 rounded-sm border item">
                                <img :src="element" class="w-full" alt="">
                                <div class="list absolute flex items-end justify-end p-2 w-full h-full bg-black left-0 top-0 bg-opacity-0 opacity-0 transition cursor-move duration-300 hover:opacity-100 hover:bg-opacity-40">
                                    <div class="flex items-center">
                                        <a @click.prevent="onRemoveImage(i)" href="#" class="flex justify-center items-center bg-white h-8 w-8 rounded-sm hover:text-red-500">
                                            <i class="icon-trash"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div  slot="footer" class="relative">
                                <div v-if="error.images" class="absolute text-xs bg-red-500 text-white rounded-sm py-1 px-2 -top-8">
                                    {{error.images}}
                                    <div class="absolute bg-red-500 left-2 -bottom-1 h-2 w-2  transform rotate-45"></div>
                                </div>
                                <div v-if="images.length < 5" class="group relative flex items-center justify-center w-40 h-40 rounded-sm border-2 border-dashed bg-gray-100">
                                    <div class="">
                                        <i class="icon-stack-plus text-gray-300 icon-2x"></i>
                                    </div>
                                    <div class="absolute flex items-end justify-end w-full h-full bg-gray-300 left-0 top-0 bg-opacity-0 opacity-0 transition duration-400 hover:opacity-100 hover:bg-opacity-30">
                                        <input @input="onChangeFileImage" class="h-full w-full border opacity-0 cursor-pointer" type="file" multiple accept=".png, .jpg">
                                    </div>
                                </div>
                            </div>
                        </draggable>
                    </div>
                </div>
            </div>

            <div ref="productinfo" class="bg-white border rounded-sm p-5 mb-5">
                <div class="mb-5">
                    <h6 class="text-lg">Product Info</h6>
                </div>
                <div class="mb-5">
                    <label for="">Product Name <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                    <input v-model="form.name" @keydown.tab="$refs.brand.open"  type="text" :class="{'border-red-500': error.name}" @keydown="error.name=''" class="form-control mt-2" placeholder="Product name">
                    <span class="text-red-500">{{error.name}}</span>
                </div>
                <div class="mb-5 grid grid-cols-3 gap-5">
                    <div :class="{'err': error.brandId}">
                        <div class="mb-2">
                            <label for="">Brand <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        </div>
                        <select-2 ref="brand" v-model="form.brandId" @input="error.brandId=''" :options="brandLists" :sort="'asc'" @onAdd="onShowModalBrand" :button="true" :buttonText="'+ add new brand'" class="form-control err" :placeholder="'Select brand'">
                            <option value=""></option>
                        </select-2>
                        <span class="text-red-500">{{error.brandId}}</span>
                    </div>
                    <div>
                        <div class="mb-2">
                            <label for="">Model / Type</label>
                            <input v-model="form.model" type="text" class="form-control mt-2" placeholder="Model / Type">
                        </div>
                    </div>
                    <div :class="{'err': error.categoriesId}">
                        <div class="mb-2">
                            <label for="">Category <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        </div>
                        <select-2 v-model="form.categoriesId" @input="error.categoriesId=''" :options="categoryLists" :sort="'asc'" @onAdd="onShowModalCategory" :button="true" :buttonText="'+ add new category'" class="form-control" :placeholder="'Select category'" :multiple="'multiple'">
                            <option value=""></option>
                        </select-2>
                        <span class="text-red-500">{{error.categoriesId}}</span>
                    </div>
                </div>
            </div>

            <div ref="productdescription" class="bg-white border rounded-sm p-5 mb-5">
                <div class="mb-5">
                    <h6 class="text-lg">Product Detail</h6>
                </div>
                <div class="grid grid-cols-12 mb-10">
                    <div class="col-span-2">
                        <h5 class="font-semibold">Condition</h5>
                    </div>
                    <div class="col-span-10 flex space-x-5">
                        <div class="form-radio">
                            <input v-model="form.condtion" :value="'NEW'" type="radio" id="radioinline1" name="radioinline" checked>
                            <label for="radioinline1">New</label>
                        </div>
                        <div class="form-radio">
                            <input v-model="form.condition" :value="'USED'" type="radio" id="radioinline2" name="radioinline">
                            <label for="radioinline2">Used</label>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-12">
                    <div class="col-span-2 font-semibold">
                        <div>Description</div>
                        <span class="text-red-500 font-normal">{{error.description}}</span>
                    </div>
                    <div class="col-span-10">
                        <textarea class="codemirror"></textarea>
                    </div>
                </div>
                <div class="mt-10 mb-5">
                    <div class="grid grid-cols-12">
                        <div class="col-span-2">
                            <div class="font-medium text-sm mt-2">Product Videos</div>
                        </div>
                        <div class="col-span-10">
                            <button v-if="!youtube.add" @click.prevent="youtube.add = true" type="button" class="px-3 h-9 border border-teal-600 text-teal-600 rounded-sm focus:outline-none hover:bg-gray-50">Add url Video</button>
                            <template v-if="form.videos.length > 0">
                                <div v-for="(video, i) in videos" :key="i" class="border rounded-sm flex items-center p-3 mb-5">
                                    <img :src="video.thumbnails.default.url" width="70" alt="">
                                    <div class="ml-2 mr-3 overflow-x-hidden">
                                        <h6 class="font-semibold truncate">{{video.title}}</h6>
                                        <div class="text-gray-400 truncate">{{video.description}}</div>
                                    </div>
                                    <div class="mx-3">
                                        <a @click.prevent="onRemoveVideo(i)" href="#"><i class="icon-trash text-gray-500"></i></a>
                                    </div>
                                </div>
                            </template>
                            <div v-if="youtube.add" class="flex items-center">
                                <input v-model="youtube.url" type="text" @input="youtube.error=''" :class="{'border-red-500': youtube.error}" class="form-control" placeholder="enter youtube url">
                                <button @click.prevent="getYoutube"  type="button" class="bg-teal-600 h-9 w-20 rounded-sm text-white ml-3 hover:bg-teal-700 focus:outline-non">
                                    <span v-if="!youtube.isLoading">save</span>
                                    <i v-if="youtube.isLoading" class="icon-spinner2 animate-spin"></i>
                                </button>
                            </div>
                            <span v-if="youtube.error" class="text-red-500">{{youtube.error}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div ref="productmanagement" class="bg-white border rounded-sm p-5 mb-5">
                <div class="mb-5">
                    <h6 class="text-lg">Product Management</h6>
                </div>
                <div class="grid grid-cols-3 gap-5 mb-5">
                    <div class="col-span-2 relative">
                        <label for="">SKU / Barcode <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        <div class="flex mt-2">
                            <span :class="{'border-red-500': error.sku}" class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-barcode2"></i></span>
                            <input v-model="form.sku" type="text" :class="{'border-red-500': error.sku}" class="text-center block w-full h-9 border border-l-0 border-r-0 px-3 focus:outline-none" placeholder="SKU or Barcode">
                            <button @click.prevent="onGenerate" type="button" :class="[error.sku ? 'border-red-500':'border-teal-600']" class="h-9 border rounded-r-sm px-3 bg-teal-600 text-white focus:outline-none hover:bg-teal-700">Generated</button>
                        </div>
                    </div>
                    <div class="col-span-1 mx-auto">
                        <div class="mb-3">Serial Number <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Optional</span></div>
                        <div class="form-radio flex justify-center">
                            <input v-model="form.isSerialNumber" id="serialnumber" type="checkbox">
                            <label for="serialnumber">Yes</label>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-3 gap-5 mb-5">
                    <div class="col-span-2 relative">
                        <div class="mb-2">
                            <label for="">Default Warehouse Location <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        </div>
                        <select-2 v-model="form.sectionId" @input="onSelectWarehouse($event)" class="form-control" :placeholder="'Select warehouse'" :options="warehouseLists" >
                            <option value=""></option>
                        </select-2>
                        {{form.warehouse}}
                    </div>
                    <div class="col-span-1">
                        <div class="mb-2">Initial Stock On Hand</div>
                        <autonumeric :placeholder="'Initial Stock On Hand'" v-model="form.qtyStock" :options="['integer', {modifyValueOnWheel : false}]" class="form-control"></autonumeric>
                    </div>
                </div>
            </div>

            <div ref="productprice" class="bg-white border rounded-sm p-5 mb-5">
                <div class="mb-5">
                    <h6 class="text-lg">Product Price</h6>
                </div>
                <div class="grid grid-cols-3 gap-5 mb-5">
                    <div class="col-span-1">
                        <label for="">Purchase Price</label>
                        <div class="flex items-center mt-2">
                            <dropdown 
                                :items="currencyLists"
                                tabindex="-1"
                                class="w-40"
                                v-model="currency"
                                >
                                <template #default="data">
                                    <div class="cursor-pointer border rounded-sm rounded-r-none bg-white h-9 flex items-center" :class="data.show ? 'rounded-b-none':''">
                                        <button class="w-full flex h-full items-center px-3 focus:outline-none">
                                            <span v-if="currency" class="mr-auto flex items-center">{{currency}}</span>
                                            <span v-else class="mr-auto text-gray-400">Currency</span>
                                            <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                        </button>
                                    </div>
                                </template>
                                <template #items="items">
                                    <div>{{items.item.name}}</div>
                                </template>
                            </dropdown>
                            <autonumeric v-model="form.purchasePrice" :options="{currencySymbol: form.currencySymbol, modifyValueOnWheel : false}" ref="purchase_price" class="text-right h-9 w-full border border-l-0 rounded-r-sm focus:outline-none px-3"></autonumeric>
                        </div>
                    </div>
                    <div class="col-span-1 relative">
                        <label for="">Selling Price <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        <div class="mt-2">
                            <autonumeric v-model="form.sellingPrice" :options="{modifyValueOnWheel : false}" @input="error.sellingPrice=''" :class="{'border-red-500': error.sellingPrice}" class="form-control text-right"></autonumeric>
                        </div>
                        <span class="text-red-500">{{error.sellingPrice}}</span>
                    </div>
                    <div class="col-span-1">
                        <label for="">Net Price</label>
                        <div class="mt-2">
                            <autonumeric v-model="form.netPrice" :options="{modifyValueOnWheel : false}" class="form-control text-right"></autonumeric>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white border rounded-sm p-5 mb-5">
                <div class="flex items-center justify-between">
                    <div>
                        <h6 class="text-lg">Wholesale Price</h6>
                    </div>
                    <div>
                        <toggler v-model="wholesale" @input="onWholesale(wholesale)"></toggler>
                    </div>
                </div>
                <div id="wholesale" class="mt-5 hidden">
                    <div class="grid grid-cols-12 gap-5">
                        <div class="col-span-3">
                            Wholesale Price
                        </div>
                        <div class="col-span-8">
                            <div class="grid grid-cols-2">
                                <div class="col-span-1">
                                    Min Qty
                                </div>
                                <div class="col-span-1">
                                    Price
                                </div>
                            </div>
                            <div v-for="(wholesale, i) in form.wholesale" :key="i" class="grid grid-cols-2 mb-3">
                                <div class="col-span-1">
                                    <div class="flex items-center">
                                        <div class="w-full flex items-center">
                                            <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm">≥</span>
                                            <autonumeric v-model="wholesale.minQty" :options="['integer',{modifyValueOnWheel : false}]" class="h-9 text-right border border-l-0 rounded-r-sm  w-full px-3 focus:outline-none"></autonumeric>
                                        </div>
                                        <div class="mx-3">
                                            =
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="flex items-center">
                                        <autonumeric v-model="wholesale.price" :options="{modifyValueOnWheel : false}" class="form-control"></autonumeric>
                                        <a @click.prevent="onRemoveWholesale(i)" href="#" class="ml-3"><i class="icon-trash"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="pr-7">
                                <button @click.prevent="onAddWholesale" type="button" class="w-full h-9 border border-dashed rounded-md text-blue-500 border-blue-500 hover:bg-blue-100"><i class="icon-add mr-2"></i> add wholesale price</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref="productmeasurement" class="bg-white border p-5 rounded-sm mb-5">
                <div class="mb-5">
                    <h6 class="text-lg">Measurements</h6>
                </div>
                <div class="grid grid-cols-4 gap-5">
                    <div v-for="(unt, i) in form.measurements" :key="i">
                        <div v-if="i !='weight'" class="col-span-1">
                            <label for=""><span class="capitalize">{{i}}</span> <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Optional</span></label>
                            <div class="flex mt-2">
                                <autonumeric v-model="unt.value" :options="{modifyValueOnWheel : false}" class="h-9 border w-full border-r-0 rounded-l-sm text-right focus:outline-none px-3"></autonumeric>
                                <dropdown 
                                    :items="units"
                                    class="w-28"
                                    v-model="unit"
                                    >
                                    <template #default="data">
                                        <div class="cursor-pointer border rounded-sm rounded-l-none bg-gray-100 h-9 flex items-center" :class="data.show ? 'rounded-b-none':''">
                                            <button class="w-full flex h-full items-center px-3 focus:outline-none" tabindex="-1">
                                                <span class="mr-auto flex items-center">{{unit}}</span>
                                                <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                            </button>
                                        </div>
                                    </template>
                                    <template #items="items">
                                        <div>{{items.item.name}}</div>
                                    </template>
                                </dropdown>
                            </div>
                        </div>
                        <div v-if="i == 'weight'" class="col-span-1">
                            <label for=""><span class="capitalize">{{i}}</span> <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                            <div class="flex mt-2">
                                <autonumeric v-model="unt.value" :options="{modifyValueOnWheel : false}" @input="error.weight=''" :class="{'border-red-500': error.weight}" class="h-9 border w-full border-r-0 rounded-l-sm text-right focus:outline-none px-3"></autonumeric>
                                <dropdown 
                                    :items="weights"
                                    class="w-28"
                                    v-model="unt.unit"
                                    >
                                    <template #default="data">
                                        <div class="cursor-pointer border rounded-sm rounded-l-none bg-gray-100 h-9 flex items-center" :class="[data.show ? 'rounded-b-none':'', {'border-red-500': error.weight}]">
                                            <button class="w-full flex h-full items-center px-3 focus:outline-none" tabindex="-1">
                                                <span class="mr-auto flex items-center">{{unt.unit}}</span>
                                                <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                            </button>
                                        </div>
                                    </template>
                                    <template #items="items">
                                        <div>{{items.item.name}}</div>
                                    </template>
                                </dropdown>
                            </div>
                            <span class="text-red-500">{{error.weight}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white border rounded-sm p-5 mb-5">
                <div class="flex items-center justify-between">
                    <div>
                        <h6 class="text-lg">Notes & Attchments</h6>
                    </div>
                    <div>
                        <toggler v-model="form.notes.status"></toggler>
                    </div>
                </div>
                <div id="note" class="mt-5 hidden">
                    <div class="grid grid-cols-8 mb-5">
                        <div class="col-span-2">
                            <div class="mt-3">Internal Note</div>
                        </div>
                        <div class="col-span-6">
                            <textarea v-model="form.notes.internal" class="w-full border p-3 focus:outline-none rounded-sm" rows="2"></textarea>
                        </div>
                    </div>
                    <div class="grid grid-cols-8 mb-5">
                        <div class="col-span-2">
                            <div class="mt-3">External Note</div>
                        </div>
                        <div class="col-span-6">
                            <textarea v-model="form.notes.external" class="w-full border p-3 focus:outline-none rounded-sm" rows="2"></textarea>
                        </div>
                    </div>
                    <legend class="flex items-center justify-between mb-3 py-2 border-b">
                        <div class="font-medium">FILE ATTCHMENTS</div>
                        <div class="relative border rounded-sm">
                            <div class="flex items-center px-3 py-1">
                                <div><i class="icon-file-upload mr-2"></i></div>
                                <div>Add more file</div>
                            </div>
                            <div class="absolute w-full h-full bg-black top-0 opacity-0 rounded-sm hover:opacity-5">
                                <input ref="fileattachment" @input="onChangeFileAttachment" class="opacity-0 absolute right-0 w-32 h-full" type="file" multiple accept=".pdf, .xlsx, .docx">
                            </div>
                        </div>
                    </legend>
                    <div>
                        <div v-for="(file ,i) in attachments" :key="i" class="flex items-center mb-3 px-5 py-2 rounded-sm border">
                            <div v-if="new RegExp('pdf').test(file.name)">
                                <i class="icon-file-pdf icon-2x text-red-600"></i>
                            </div>
                            <div v-if="new RegExp('docx').test(file.name)">
                                <i class="icon-file-word icon-2x text-blue-800"></i>
                            </div>
                            <div v-if="new RegExp('xlsx').test(file.name)">
                                <i class="icon-file-excel icon-2x text-green-800"></i>
                            </div>
                            <div class="ml-3">
                                <div class="font-semibold">{{file.name}}</div>
                                <div class="text-gray-400">{{file.size / 1000}} KB</div>
                            </div>
                            <div class="ml-auto">
                                <a @click.prevent="removeAttachement(i)" href="#" class="text-red-500">
                                    <i class="icon-cancel-circle2"></i>
                                </a>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="bg-white border rounded-sm p-5 mb-5">
                <div class="flex items-center justify-between">
                    <div>
                        <h6 class="text-lg">Product Accessories</h6>
                    </div>
                    <div>
                        <toggler v-model="accessories.status" @input="onAccessories(accessories.status)"></toggler>
                    </div>
                </div>
                <div id="accessories" class="mb-5 mt-5 hidden">
                    <autocomplete v-model="autocomplete" :notin="productAccessories" @input="onSelectAutocomplete" :url="'/products/accessories'" :placeholder="'Search product name'">
                        <template #default="item">
                            <div class="flex items-center">
                                <img :src="imgURL+'/public/img/products/200/'+item.item.images[0]" width="50" alt="">
                                <div class="ml-3">
                                    <h6 class="font-medium">{{item.item.name}}</h6>
                                    <div class="text-gray-400">{{item.item.sku}}</div>
                                </div>
                            </div>
                        </template>
                    </autocomplete>
                    <div class="mt-3 overflow-x-hidden">
                        <table class="w-full">
                            <tbody>
                                <tr v-for="(acs, i) in accessories.accessories" :key="i" class="animated fadeInLeft">
                                    <td class="py-3 px-5">
                                        <div class="flex items-center">
                                            <img :src="imgURL+'/public/img/products/200/'+acs.image" alt="" width="70">
                                            <div class="ml-3">
                                                <h6 class="text-sm font-medium">{{acs.name}}</h6>
                                                <div class="text-gray-400">SKU: {{acs.sku}}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-right py-3 px-5">
                                        <a @click.prevent="onRemoveAccessories(i)" href="#" class="text-red-500">
                                            <i class="icon-cancel-circle2"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="bg-white border rounded-sm p-5 mb-5">
                <div class="flex items-center justify-between">
                    <div>
                        <h6 class="text-lg">Pre Order</h6>
                    </div>
                    <div>
                        <toggler v-model="form.preorder.isActive" @input="onPreorder(form.preorder.isActive)"></toggler>
                    </div>
                </div>
                <div id="preorder" class="mt-5 hidden">
                    <div class="grid grid-cols-12">
                        <div class="col-span-3"></div>
                        <div class="col-span-6">
                            <div class="flex w-full">
                                <autonumeric v-model="form.preorder.duration" :options="['integer', {modifyValueOnWheel : false}]" :placeholder="'Duration'" class="h-9 border text-right w-full border-r-0 rounded-l-sm focus:outline-none px-3"></autonumeric>
                                <dropdown 
                                    :items="times"
                                    class="w-20"
                                    v-model="form.preorder.timeUnit"
                                    >
                                    <template #default="data">
                                        <div class="cursor-pointer border rounded-sm rounded-l-none bg-gray-100 h-9 flex items-center" :class="data.show ? 'rounded-b-none':''">
                                            <button class="w-full flex h-full items-center px-3 focus:outline-none">
                                                <span class="mr-auto flex items-center">{{form.preorder.timeUnit}}</span>
                                                <i :class="{'-rotate-180': data.show}" class="transform duration-200 icon-arrow-down22"></i>
                                            </button>
                                        </div>
                                    </template>
                                    <template #items="items">
                                        <div>{{items.item.name}}</div>
                                    </template>
                                </dropdown>
                            </div>
                        </div>
                        <div class="col-span-3"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="flex items-center justify-end">
                <button @click="onCancel" type="button" class="px-5 py-2 bg-white border rounded-sm  focus:outline-none hover:bg-gray-50 mr-2">Cancel</button>
                <button type="submit" class="relative px-5 py-2 bg-teal-600 rounded-sm text-white focus:outline-none hover:bg-teal-700" :disabled="isDisabled">
                    <div v-if="isDisabled" class="absolute bg-teal-400 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    Save
                </button>
            </div> -->
        </form>


        <modal :show="modalNewBrand">
            <form @submit.prevent="onSubmitBrand" class="bg-white border rounded-sm p-5 w-1/2 vld-parent">
                <div class="mb-5">
                    <h6 class="text-lg uppercase">Add New Brand</h6>
                </div>
                <div>
                    <div class="mb-5">
                        <label for="">Brand Name <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        <input v-model="formBrand.name" ref="brandname" type="text" class="form-control my-2" :class="{'border-red-500': errorBrand.name}" @keydown="errorBrand.name = ''" placeholder="Brand name">
                        <span class="text-red-500">{{errorBrand.name}}</span>
                    </div>
                    <div class="mb-5">
                        <label for="">Description <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Optional</span></label>
                        <input v-model="formBrand.description" type="text" class="form-control mt-2" placeholder="Description">
                    </div>
                    <div class="mb-5">
                        <div class="mb-2">LOGO</div>
                        <div class="group relative flex items-stretch cursor-pointer">
                            <input ref="fileImage" @change="onLogoBrandChange" type="file" class="w-full absolute z-10 opacity-0 cursor-pointer">
                            <span class="flex w-full items-center bg-white px-3 border h-9 rounded-l-sm cursor-pointer whitespace-nowrap overflow-hidden">{{logBrandName}}</span>
                            <span class="bg-blue-500 h-9 whitespace-nowrap px-3 flex items-center rounded-r-sm text-white group-hover:bg-blue-600 cursor-pointer">Choose image</span>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end">
                    <button @click="onCloseModalBrand" type="button" class="h-9 px-5 bg-red-600 mr-2 text-white rounded-sm focus:outline-none hover:bg-red-700">Cancel</button>
                    <button class="h-9 px-5 bg-teal-600 text-white rounded-sm focus:outline-none hover:bg-teal-700" :disabled="isDisabled">Save</button>
                </div>
            </form>
        </modal>

        <modal :show="modalCategory">
            <form @submit.prevent="onSubmitCategory" class="bg-white border rounded-sm p-5 w-1/2 vld-parent">
                <div class="mb-5">
                    <h6 class="text-lg uppercase">Add New Category</h6>
                </div>
                <div>
                    <div class="mb-5">
                        <label for="">Category Name <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Required</span></label>
                        <input v-model="formCategory.name" ref="categoryname" type="text" class="form-control my-2" :class="{'border-red-500': errorCategory.name}" @keydown="errorCategory.name = ''" placeholder="Category name">
                        <span class="text-red-500">{{errorCategory.name}}</span>
                    </div>
                    <div class="mb-5">
                        <label for="">Description <span class="text-gray-400 text-xs bg-gray-100 p-1 rounded-sm">Optional</span></label>
                        <input v-model="formCategory.description" type="text" class="form-control mt-2" placeholder="Description">
                    </div>
                </div>
                <div class="flex justify-end">
                    <button @click="onCloseModalCategory" type="button" class="h-9 px-5 bg-red-600 mr-2 text-white rounded-sm focus:outline-none hover:bg-red-700">Cancel</button>
                    <button class="h-9 px-5 bg-teal-600 text-white rounded-sm focus:outline-none hover:bg-teal-700" :disabled="isDisabled">Save</button>
                </div>
            </form>
        </modal>
    </div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import draggable from 'vuedraggable'
import Swal from 'sweetalert2/dist/sweetalert2'
import select2 from '../../components/Select2.vue'
import Modal from '../../components/Modal.vue'
import Dropdown from '../../components/Dropdown.vue'
import autonumeric from '../../components/Autonumeric.vue'
import toggler from '../../components/Toggler.vue'
import autocomplete from '../../components/Autocomplete.vue'
import 'summernote/dist/summernote-lite.js'
import 'summernote/dist/summernote-lite.css'
import BreadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
export default {
    components: {draggable, select2, Modal, Dropdown, autonumeric, toggler, autocomplete},
    mixins: [BreadcrumbsManager],
    data () {
        return {
            imgURL: process.env.VUE_APP_ROOT_API,
            isDisabled: false,
            warehouses: [],
            images: [],
            attachments: [],
            brands: [],
            modalNewBrand: false,
            logBrandName: '',
            formBrand: {
                name: '',
                description: '',
                logo: ''
            },
            errorBrand: {
                name: ''
            },
            categories: [],
            modalCategory: false,
            formCategory: {
                name: '',
                description: ''
            },
            errorCategory: {
                name: ''
            },
            youtube: {
                add: false,
                url: '',
                error: '',
                isLoading: false
            },
            videos: [],
            currencies: [],
            currency: '',
            wholesale: false,
            unit: 'mm',
            units: [{name: 'mm'},{name: 'cm'},{name: 'm'},{name: 'inch'}],
            weights: [{name: 'gr'},{name: 'kg'},{name: 'oz'},{name: 'lb'}],
            autocomplete: '',
            times: [{name: 'DAY'},{name: 'WEEK'}],
            accessories: {
                status: false,
                accessories: []
            },
            form: {
                name: '',
                brandId: '',
                model: '',
                categoriesId: [],
                condition: 'NEW',
                description: '',
                videos: [],
                sku: '',
                isSerialNumber: false,
                currency: '',
                currencySymbol: '',
                purchasePrice: '',
                sellingPrice: '',
                netPrice: '',
                wholesale: [],
                measurements: {
                    width: {value: '', unit: 'mm'},
                    height: {value: '',unit: 'mm'},
                    depth: {value: '',unit: 'mm'},
                    weight: {value: '',unit: 'gr'},
                },
                notes: {
                    status: false,
                    internal: '',
                    external: '',
                },
                accessories: [],
                preorder: {
                    isActive: false,
                    duration: '',
                    timeUnit: 'DAY'
                },
                warehouseId: '',
                sectionId: '',
                qtyStock: '',
            },
            error: {
                images: '',
                name: '',
                brandId: '',
                categoriesId: '',
                description: '',
                sku: '',
                warehouseId: '',
                sellingPrice: '',
                weight: '',
            }
        }
    },
    mounted () {
        this.getData()
        let app = this
        $('.codemirror').summernote({
            callbacks: {
                onChange: function(contents) {
                    app.form.description = contents
                    app.error.description = ''
                }
            },
            placeholder: 'Product description',
            tabsize: 2,
            height: 400,
            fontNames: ['Arial','Arial Black', 'Comic Sans Ms','Courier New', 'DIN Alternate', 'Helvetica Neue','Helvetica','Impact','Lucida Grande','Tahoma','Times New Roman', 'Verdana','Roboto','system-ui'],
            toolbar: [
                ['style', ['style']],
                ['font', ['bold', 'underline', 'clear']],
                ['fontname', ['fontname']],
                ['fontsize', ['fontsize']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']],
            ],
        });
        this.setButtonText('SAVE')
        this.setDiscard(true)
        this.setDiscardText('DISCARD')
        this.pushBreadcrumb({
            text: 'New'
        })
        EventBus.$on('onClick', this.onSubmit)
        EventBus.$on('onCancel', this.onCancel)
    },
    destroyed() {
        EventBus.$off('onClick', this.onSubmit)
        EventBus.$off('onCancel', this.onCancel)
    },
    computed: {
        imageSort () {
            const list = []
            for (let i = 0; i < this.images.length; i++) {
                list.push({index: i, file: this.images[i].file})
            }
            return list
        },
        brandLists () {
            return this.brands.map(obj => {
                obj.id = obj._id
                obj.text = obj.name.replace(/\b\w/g, (c) => c.toUpperCase())
                return obj
            })
        },
        categoryLists () {
            return this.categories.map(obj => {
                obj.id = obj._id
                obj.text = obj.name.replace(/\b\w/g, (c) => c.toUpperCase())
                return obj
            })
        },
        warehouseLists () {
            return this.warehouses.map(obj => {
                obj.id = obj._id
                obj.text = obj.name
                obj.children = obj.sections.map(obs => {
                    obs.id = obs._id
                    obs.text = obj.name+' - '+obs.name
                    return obs
                })
                return obj
            })
        },
        currencyLists () {
            return this.currencies.map(obj => {
                obj.name = obj.code 
                return obj
            })
        },
        productAccessories () {
            return this.accessories.accessories.map(obj => {
                return obj._id
            })
        }
    },
    methods: {
        onSelectWarehouse (e) {
            const inventory = this.warehouses.find((ws) => {
                return ws.children.some((section) => {
                    return section.id == e
                })
            })
            this.form.warehouseId = inventory._id
            this.form.sectionId = e
        },
        getData () {
            axios.get('/products/create')
            .then(res => {
                this.brands = res.data.brands
                this.categories = res.data.categories
                this.currencies = res.data.currencies,
                this.warehouses = res.data.warehouses
                this.$nextTick(() => {
                    const isDefault = this.warehouseLists.find(wr => wr.isDefault == true)
                    if(isDefault) {
                        this.form.sectionId = isDefault.sections[0]._id
                        this.form.warehouseId = isDefault._id
                    }
                })
            })
        },
        async onSubmit () {
            function dataURItoBlob(dataURI) {
                var byteString = atob(dataURI.split(',')[1]);
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                var blob = new Blob([ab], {type: mimeString});
                return blob;
            }
            
            const productinfo = this.$refs.productinfo.offsetTop - 68
            const productdescription = this.$refs.productdescription.offsetTop - 68
            const productmanagement = this.$refs.productmanagement.offsetTop - 68
            const productprice = this.$refs.productprice.offsetTop - 68
            const productmeasurement = this.$refs.productmeasurement.offsetTop - 68
            
            if(!this.form.measurements.weight.value) {
                this.error.weight = 'Weight is required'
                window.scrollTo(0, productmeasurement)
            }
            if(!this.form.sellingPrice) {
                this.error.sellingPrice = 'Selling price is required'
                window.scrollTo(0, productprice)
            }
            if(!this.form.sku) {
                this.error.sku = 'SKU is required'
                window.scrollTo(0, productmanagement)
            }
            if(!this.form.warehouseId) {
                this.error.warehouseId = 'Warehause is required'
                window.scrollTo(0, productmanagement)
            }
            if(!this.form.description) {
                this.error.description = 'Description is required'
                window.scrollTo(0, productdescription)
            }
            if(this.form.categoriesId.length < 1) {
                this.error.categoriesId = 'Category is required'
                window.scrollTo(0, productinfo)
            }
            if(!this.form.brandId) {
                this.error.brandId = 'Brand is required'
                window.scrollTo(0, productinfo)
            }
            if(!this.form.name) {
                this.error.name = 'Product name is required'
                window.scrollTo(0, productinfo)
            }
            // if(this.images.length < 1) {
            //     this.error.images = 'Product images is required'
            //     window.scrollTo(0,0)
            // }
            if(this.form.measurements.weight.value && this.form.sellingPrice && this.form.sku && this.form.description && this.form.categoriesId.length > 0 && this.form.brandId && this.form.name) {
                this.isDisabled = true
                this.setDisabled(true)
                const formData = new FormData()
                const files = []
                const categories = JSON.stringify(this.form.categoriesId)
                const videos = JSON.stringify(this.form.videos)
                const wholesale = JSON.stringify(this.form.wholesale)
                const measurements = JSON.stringify(this.form.measurements)
                const notes = JSON.stringify(this.form.notes)
                const accessories = JSON.stringify(this.productAccessories)
                const preorder = JSON.stringify(this.form.preorder)
                for await (let image of this.images) {
                    const blob = dataURItoBlob(image)
                    files.push(blob)
                } 
                for (let i = 0; i < files.length; i++) {
                    formData.append('images', files[i])
                }
                for (let i = 0; i < this.attachments.length; i++) {
                    formData.append('files', this.attachments[i])
                }
                formData.append('name', this.form.name)
                formData.append('brandId', this.form.brandId)
                formData.append('model', this.form.model)
                formData.append('categoriesId', categories)
                formData.append('condition', this.form.condition)
                formData.append('description', this.form.description)
                formData.append('videos', videos)
                formData.append('sku', this.form.sku)
                formData.append('isSerialNumber', this.form.isSerialNumber)
                formData.append('currency', this.form.currency)
                formData.append('currencySymbol', this.form.currencySymbol)
                formData.append('purchasePrice', this.form.purchasePrice)
                formData.append('sellingPrice', this.form.sellingPrice)
                formData.append('netPrice', this.form.netPrice)
                formData.append('wholesale', wholesale)
                formData.append('measurements', measurements)
                formData.append('notes', notes)
                formData.append('accessories', accessories)
                formData.append('preorder', preorder)
                formData.append('warehouseId', this.form.warehouseId)
                formData.append('sectionId', this.form.sectionId)
                formData.append('qtyStock', this.form.qtyStock)
                axios.post('/products/create', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.setDisabled(false)
                    this.isDisabled = false
                    this.$router.push('/products')
                })
                .catch((err) => {
                     Swal.fire({
                        title: 'ERROR',
                        text: JSON.stringify(err.response),
                        type: 'error',
                        confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                    });
                    this.isDisabled = false
                    this.error.sku = 'SKU is already exists'
                    window.scrollTo(0, productmanagement)
                })
            }
        },
        //  IMAGES FUNCTION
        onChangeFileImage (e) {
            this.error.images = ''
            let app = this
            const file = e.currentTarget.files
            if(file.length + this.images.length > 5) {
                Swal.fire({
                    title: 'Limit!',
                    text: 'Your upload images max limit is 5, your remaining upload is '+(5 - this.images.length),
                    type: 'warning',
                    confirmButtonClass:'bg-teal-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-teal-600',
                });
            } 
            if(file.length + this.images.length <= 5) {
                for (let i = 0; i < file.length; i++) {
                    const reader = new FileReader()
                    if(this.images.length < 5) {
                        reader.onload = () => {
                            app.images.push(reader.result)
                        }
                        reader.readAsDataURL(file[i])
                    }
                }
                e.target.value = ""
            }         
        },
        onRemoveImage (i) {
            this.images.splice(i,1)
        },

        // BRAND FUNCTION
        onShowModalBrand () {
            this.modalNewBrand = true
            this.$nextTick(() => {
                this.$refs.brandname.focus()
            })
        },
        onCloseModalBrand () {
            this.modalNewBrand = false
            this.formBrand.name = ''
            this.formBrand.description = ''
            this.errorBrand.name = ''
        },
        onLogoBrandChange (e) {
            const file = e.target.files[0]
            this.logBrandName = file.name
            this.formBrand.logo = file
        },
        onSubmitBrand () {
            if(!this.formBrand.name) {
                this.errorBrand.name = 'Brand name is required'
            }
            if(this.formBrand.name) {
                this.isDisabled = true
                const formData = new FormData()
                formData.append('name', this.formBrand.name)
                formData.append('description', this.formBrand.description)
                formData.append('logo', this.formBrand.logo)

                axios.post('/brands/create', formData, {
                    headers: {
                        'Content-type' : 'multipart/form-data'
                    }
                })
                .then(res => {
                    this.modalNewBrand = false
                    this.isDisabled = false
                    this.brands.push(res.data)
                    this.$nextTick(() => {
                        this.form.brandId = res.data._id
                    })
                    this.formBrand.name = ''
                    this.formBrand.description = ''
                    this.errorBrand.name = ''
                })
                .catch(() => {
                    this.isDisabled = false
                    this.errorBrand.name = 'Brand name is already exists'
                })
            }
        },

        // CATEGORY FUNCTION
        onShowModalCategory () {
            this.modalCategory = true
            this.$nextTick(() => {
                this.$refs.categoryname.focus()
            })
        },
        onCloseModalCategory () {
            this.modalCategory = false
            this.formCategory.name = ''
            this.formCategory.description  = ''
            this.errorCategory.name = ''
        },
        onSubmitCategory () {
            if(!this.formCategory.name) {
                this.errorCategory.name = 'Category name is required'
            }
            if(this.formCategory.name) {
                this.isDisabled = true
                axios.post('/categories/create', this.formCategory)
                .then(res => {
                    this.isDisabled = false
                    this.modalCategory = false
                    this.categories.push(res.data)
                    this.$nextTick(() => {
                        this.form.categoriesId.push(res.data._id)
                    })
                    this.formCategory.name = ''
                    this.formCategory.description = ''
                    this.errorCategory.name = ''
                })
                .catch(() => {
                    this.isDisabled = false
                    this.errorCategory.name = 'Category name is already exists'
                })
            }
        },

        // YOUTUBE FUNCITON
        getYoutube () {
            const url = new URL(this.youtube.url)
            const id = url.searchParams.get('v')
            if(!id) {
                this.youtube.error = 'Video URL is not correct'
            }
            if(id) {
                const filter = this.form.videos.find(obj => obj.url == id)
                if(filter) {
                    this.youtube.error = 'Your has been add this video'
                } else {
                    this.youtube.isLoading = true
                    axios.post('/products/youtube', {id: id})
                    .then(res => {
                        this.youtube.isLoading = false
                        if(res.data) {
                            this.form.videos.push({url: id})
                            this.videos.push(res.data.snippet)
                            this.youtube.url = ''
                        } else {
                            this.youtube.error = 'Video URL is not correct'
                        }
                    })
                    .catch(() => {
                        this.youtube.isLoading = false
                        this.youtube.error = 'Video URL is not correct'
                    })
                }
            }
        },
        onRemoveVideo  (i) {
            this.form.videos.splice(i, 1)
            this.videos.splice(i, 1)
        },

        // SKU GENERATE FUNCTION
        onGenerate () {
            this.form.sku = 'DPI-'+new Date().getTime()
            this.error.sku = ''
        },

        // WHOLESALE FUNCTION
        onWholesale (val) {
            if(val == true) {
                this.form.wholesale.push({minQty:'', price:''})
                $('#wholesale').addClass('block').slideDown(250)
            } else {
                $('#wholesale').removeClass('block').slideUp(250)
                this.form.wholesale = []
            }
        },
        onAddWholesale () {
            this.form.wholesale.push({minQty: '', price: ''})
        },
        onRemoveWholesale(i) {
            this.form.wholesale.splice(i, 1)
        },

        // NOTE & ATTACMENTS FUNCTION
        async onChangeFileAttachment (e) {
            let files = []
            for await (let file of e.target.files) {
                files.push(file)
            }
            const filter = files.filter(x => x.name != this.attachments.includes(x.name))
            filter.forEach(el => {
                this.attachments.push(el)
            })
            
        },
        removeAttachement (index) {
            this.attachments.splice(index, 1)
            this.$refs.fileattachment.value = ''
        },

        // PRODUCT ACCESSORIES FUNCTION
        onAccessories(val) {
            if(val == true) {
                $('#accessories').addClass('block').slideDown(250)
            } else {
                $('#accessories').removeClass('block').slideUp(250)
            }
        },
        onSelectAutocomplete (val) {
            if(val) {
                const filter = this.accessories.accessories.find(obj => obj._id == val._id )
                if(!filter) {
                    this.accessories.accessories.push({_id: val._id, sku:val.sku, name: val.name, image: val.images[0]})
                }
            }
        },
        onRemoveAccessories (i) {
            this.accessories.accessories.splice(i, 1)
        },

        // PREORDER FUNCTION
        onPreorder (val) {
            if(val == true) {
                $('#preorder').addClass('block').slideDown(250)
            } else {
                $('#preorder').removeClass('block').slideUp(250)
            }
        },

        // CANCEL 
        onCancel () {
            this.$router.push('/products')
        }
    },
    watch: {
        currency (val) {
            const cur = this.currencies.find(obj => obj.code == val)
            this.form.currency = cur.code
            this.form.currencySymbol = cur.symbolNative
            this.$nextTick(() => {
                this.$refs.purchase_price.$el.focus()
            })
        },
        unit (val) {
            this.form.measurements.width = {value: '', unit: val}
            this.form.measurements.height = {value: '',unit: val}
            this.form.measurements.depth = {value: '',unit: val}
        },
        'form.notes.status': function (val) {
            if(val == true) {
                $('#note').addClass('block').slideDown(250)
            } else {
                $('#note').removeClass('block').slideUp(250)
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.popBreadcrumb()
        next()
    }
}
</script>

<style scoped>
    input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
        cursor: pointer; 
        display: none;
    }
    input[type=file] {
        cursor: pointer;
    }
    .float > div { float: left; }
    textarea {resize: none}

</style>

<style>
    .err .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
        border-color: red !important;
    }
    .err .select2-selection--multiple:not([class*=bg-]):not([class*=border-]) {
        border-color: red !important;
    }
</style>