<template>
    <div>
        <div class="flex items-center justify-between border rounded-t-sm px-4 bg-white shadow-lg">
            <div>
                <stepper>
                    <li><button :class="{'active': quotation.status == 'Quotation'}">QUOTATION</button></li>
                    <li><button :class="{'active': quotation.status == 'Quotation Sent'}">QUOTATION SENT</button></li>
                    <li><button :class="{'active': quotation.status == 'Sales Order'}">SALES ORDER</button></li>
                    <li v-if="quotation.status == 'Cancelled'"><button :class="{'active': quotation.status == 'Cancelled'}">CANCELLED</button></li>
                </stepper>
            </div>
            <div v-if="quotation.status == 'Quotation'">
                <button @click="onCancelled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button @click="onConfirm" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CONFIRM</button>
                <button class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">SEND BY EMAIL</button>
            </div>
            <div v-if="quotation.status == 'Quotation Sent'">
                <button @click="onCancelled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">SEND BY EMAIL</button>
                <button @click="onConfirm" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">CONFIRM</button>
            </div>
            <div v-if="quotation.status == 'Sales Order'">
                <button @click="onCancelled" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">SEND BY EMAIL</button>
                <button class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">CREATE INVOICE</button>
            </div>
            <div v-if="quotation.status == 'Cancelled'">
                <button @click="onSetToQuotation" class="h-7 border border-transparent px-2 text-teal-600 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">SET TO QUOTATION</button>
            </div>
        </div>

        <div class="p-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                        <div @click="onTab('task')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='task'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">TASK & NOTES <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{task}}</span></div>
                        <div @click="onTab('history')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='history'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">HISTORY LOG <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{activities.length}}</span></div>
                    </div>
                    <div class="flex divide-x">
                        <div @click="onDelivery" v-show="delivery.length > 0" class="py-2 px-5 border-l flex items-center leading-none hover:bg-gray-100 cursor-pointer">
                            <i class="icon-truck text-gray-500" style="font-size: 24px"></i>
                            <div class="ml-3">
                                <div>{{delivery.length}}</div>
                                <div>Delivery</div>
                            </div>
                        </div>
                        <div v-show="invoice.length > 0" class="py-2 px-5 flex items-center leading-none hover:bg-gray-100 cursor-pointer">
                            <i class="icon-calculator text-gray-500" style="font-size: 24px"></i>
                            <div class="ml-2">
                                <div class="text-right">1</div>
                                <div>Invoices</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- OVERVIEW -->
            <div v-show="tab=='overview'" class="animated fadeIn faster">
                <div class="grid grid-cols-9 p-5 divide-x bg-white border border-t-0 border-b-0">
                    <div class="col-span-5 pr-5">
                        <div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{quotation.quotationNo}}</div>
                                <div class="text-gray-400 text-xs">QUOTATION #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">{{getDate(quotation.createdAt)}}</div>
                                <div class="text-gray-400 text-xs">DATE CREATED ({{quotation.user.name}})</div>
                            </div>
                        </div>
                        <div class="mb-3" v-if="quotation.customer">
                            <label for="" class="font-medium">CUSTOMER</label>
                            <div class="text-base font-medium">{{quotation.customer.displayName}}</div>
                            <div v-if="quotation.customer.address">
                                <div>{{quotation.customer.address.street}}</div>
                                <div>{{quotation.customer.address.street2}}</div>
                                <div>{{quotation.customer.address.subdistrictName}} {{quotation.customer.address.cityName}} {{quotation.customer.address.zip}}</div>
                                <div>{{quotation.customer.address.provinceName}}</div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="" class="font-medium">CUSTOMER REFFERENCE</label>
                            <div v-if="quotation.customerReference">{{quotation.customerReference}}</div>
                            <div v-else>--</div>
                        </div>
                        <div v-if="quotation.remarks" class="mb-3">
                            <div class="flex items-center justify-between mb-1">
                                <div>
                                    <label for="" class="font-semibold">REMARKS</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="quotation.remarks.urgent" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">Urgent</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="quotation.remarks.forYourReview" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">For Your Review</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="quotation.remarks.replyAsap" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">Reply ASAP</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <i v-if="quotation.remarks.pleaseComment" class="icon-checkbox-checked"></i>
                                    <i v-else class="icon-checkbox-unchecked"></i>
                                    <label for="">Please Comment</label>
                                </div>
                            </div>
                            <div v-if="quotation.remarks.comment">{{quotation.remarks.comment}}</div>
                            <div v-else>--</div>
                        </div>
                        <div v-if="quotation.tags">
                            <label for="" class="font-semibold">TAGS</label>
                            <div v-if="quotation.tags.length > 0" class="flex items-center space-x-0.5">
                                <div v-for="tag in quotation.tags" :key="tag" class="bg-gray-500 text-gray-50 text-xs px-1 rounded-sm py-px">{{tag}}</div>
                            </div>
                            <div v-else>--</div>
                        </div>
                    </div>
                    <div class="col-span-4 pl-5">
                        <div class="grid grid-cols-2 mb-3">
                            <div class="col-span-1">
                                <label class="font-medium" for="">ESTIMATED DELIVERY TIME</label>
                                <div>{{quotation.estimatedDeliveryTime}}</div>
                            </div>
                            <div class="col-span-1">
                                <label class="font-medium" for="">DATE VALIDATY</label>
                                <div>{{getDate(quotation.dateValidaty)}}</div>
                            </div>
                        </div>
                        <div v-if="quotation.additionalCharges">
                            <label for="" class="font-semibold">ADDITIONAL CHARGES</label>
                            <table v-if="quotation.additionalCharges.length > 0" class="w-full text-teal-500 border-b border-t mt-1 divide-y">
                                <tr v-for="(adc, i) in quotation.additionalCharges" :key="i">
                                    <td class="py-2 pl-3">{{adc.name}}</td>
                                    <td v-if="adc.isFixed" class="py-2 pr-3 text-right">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(adc.unitPrice)}}</td>
                                    <td v-else class="py-2 pr-3 text-right">{{adc.unitPrice}}%</td>
                                </tr>
                            </table>
                            <table v-else class="w-full text-teal-500 border-b border-t mt-1 divide-y">
                                <tr>
                                    <td class="py-2 pl-3">--</td>
                                    <td class="py-2 pr-3 text-right">--</td>
                                </tr>
                            </table>
                            
                        </div>
                    </div>
                </div>
                <table class="w-full table-fixed bg-white">
                    <thead class="border border-blue-500">
                        <tr class="bg-blue-500 border-b">
                            <th class="py-2.5 pl-5 pr-2  sticky z-20 text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 bg-blue-500" style="width: 40%; top: 93px">ITEM</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">UNIT PRICE</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">QTY</th>
                            <th v-show="quotation.status == 'Sales Order'" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">DELIVERED</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 2%; top: 93px"></th>
                            <th v-show="quotation.status == 'Sales Order'" class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 10%; top: 93px">INVOICED</th>
                            <th class="py-2.5 px-2 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 11%; top: 93px">DISCOUNT</th>
                            <th class="py-2.5 pl-2 pr-5 sticky z-20 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">TOTAL</th>
                        </tr>
                    </thead>
                    <tbody class="border border-b-0">
                        <tr v-for="(item, i) in quotation.items" :key="i" class="border-b">
                            <td class="pl-5 h-9 border-r">{{i+1}}</td>
                            <td class="px-2 h-9 truncate">{{item.name}}</td>
                            <td class="px-2 h-9 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.unitPrice)}}</td>
                            <td class="px-2 h-9 text-right">{{item.qty}}</td>
                            <td v-show="quotation.status == 'Sales Order'" class="px-2 h-9 text-right">{{item.delivered}}</td>
                            <td class="h-9 px-1 text-center">
                                <a href="#" :class="[item.qty > item.stock ? 'text-red-500':'text-green-500']"><i class="icon-chart"></i></a>
                            </td>
                            <td v-show="quotation.status == 'Sales Order'" class="px-2 h-9 text-right">{{item.invoiced}}</td>
                            <td v-if="item.discount.amount" class="h-9 px-2 text-right text-red-500">
                                <div v-if="item.discount.discountType == 'Fixed'">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.discount.amount)}}</div>
                                <div v-else>{{item.discount.amount}}%</div>
                            </td>
                            <td v-else class="h-9 px-2 text-right">--</td>
                            <td class="h-9 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(item.subTotal)}}</td>
                        </tr>
                        <!-- TOTOAL -->
                        <tr>
                            <td v-if="quotation.status == 'Sales Order'" colspan="7"></td>
                            <td v-else colspan="5"></td>
                            <td class="py-1 px-2 text-right">Subtotal</td>
                            <td class="py-1 pl-2 pr-5 text-right font-medium">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(quotation.total)}}</td>
                        </tr>
                        <!-- DISCOUNT -->
                        <tr v-if="quotation.discount.value" class="font-medium text-red-500">
                            <td v-if="quotation.status == 'Sales Order'" colspan="8" class="px-2 py-1 text-right">Discount on total price</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">Discount on total price</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(quotation.discount.value)}}</td>
                        </tr>
                        <!-- TAX -->
                        <tr v-if="quotation.tax.value" class="font-medium text-blue-500">
                            <td v-if="quotation.status == 'Sales Order'" colspan="8" class="px-2 text-right">
                                {{quotation.tax.code}} ({{quotation.tax.amount}}%)
                            </td>
                            <td v-else colspan="6" class="px-2 text-right">
                                {{quotation.tax.code}} ({{quotation.tax.amount}}%)
                            </td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(quotation.tax.value)}}</td>
                        </tr>
                         <!-- ADDITIONAL CHARGES -->
                        <tr v-if="quotation.totalAdditionalCharges" class="font-medium text-green-600">
                            <td v-if="quotation.status == 'Sales Order'" colspan="8" class="px-2 py-1 text-right">ADDITIONAL CHARGERS</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">ADDITIONAL CHARGERS	</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(quotation.totalAdditionalCharges)}}</td>
                        </tr>
                        <!-- SHIPPING -->
                        <tr v-if="quotation.shipping" class="font-medium">
                            <td v-if="quotation.status == 'Sales Order'" colspan="8" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td v-else colspan="6" class="px-2 py-1 text-right">SHIPMENT COST</td>
                            <td class="py-1 pl-2 pr-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(quotation.shipping.shipmentCost)}}</td>
                        </tr>
                        <!-- TOTAL -->
                        <tr class="bg-orange-100">
                            <td></td>
                            <td></td>
                            <td v-if="quotation.status == 'Sales Order'" colspan="5"></td>
                            <td v-else colspan="3" class="py-1 pl-10 pr-2.5"></td>
                            <td class="text-right text-base font-semibold h-9">TOTAL</td>
                            <td class="text-right text-base font-semibold pl-2 pr-5">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(quotation.grandTotal)}}</td>
                        </tr>
                    </tbody>
                </table>
                <div v-if="quotation.offerConditions" class="p-5 bg-white border rounded-b-sm border-t-0">
                    <div class="text-base font-medium mb-3">KONDISI PENAWARAN</div>
                    <table class="w-full">
                        <tr v-for="(condition, i) in quotation.offerConditions" :key="i">
                            <td class="pl-5 py-1">{{condition.name}} :</td>
                            <td class="pl-5 py-1">{{condition.description}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- TASK & NOTE -->
            <div v-show="tab == 'task'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <task-manager v-model="task" :documentName="quotation.quotationNo"></task-manager>   
            </div>
            <!-- HISTORY -->
            <div v-show="tab == 'history'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <history :activities="activities"></history>
            </div>
        </div>

        <!-- MODAL CANCEL ORDER -->
        <modal :show="modalCancelSalesOrder">
            <div class="w-2/5 bg-white border rounded-sm">
                <div class="px-5 py-3 border-b flex items-center justify-between">
                    <div class="font-semibold">Cancel Sales Order</div>
                </div>
                <div class="p-5">
                    Some products have already been delivered. Returns can be created from the Delivery Orders.
                </div>
                <div class="text-right px-5 py-2 border-t">
                    <button @click="modalCancelSalesOrder = false" class="h-9 rounded-sm px-5 mr-2 border border-transparent hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                    <button class="bg-teal-500 h-9 rounded-sm px-5 text-teal-50 hover:bg-teal-600">CONFIRM</button>
                </div>
            </div>
        </modal>

        <!-- PDF -->
        <div class="hidden">
            <quotation-pdf :data="quotation" id="printJS-form"></quotation-pdf>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import stepper from '../../../components/stepper.vue'
import BreadcrumbManager from '../../../mixins/breadcrumbsManager.vue'
import TaskManager from '../../../components/taskManager.vue'
import History from '../../../components/history.vue'
import {EventBus} from '../../../event-bus'
import {moment} from '../../../moment'
import Modal from '../../../components/Modal.vue'
import quotationPdf from '../../../components/quotaitonPdf.vue'
import printJS from 'print-js'
export default {
    components: {stepper, TaskManager, History, Modal, quotationPdf},
    mixins: [BreadcrumbManager],
    data () {
        return {
            modalCancelSalesOrder: false,
            isLoading: false,
            tab: 'overview',
            delivery: [],
            invoice: [],
            quotation: {
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                },
                user: '',
            },
            task: '',
            activities: []
        }
    },
    mounted () {
        this.setButtonText('EDIT')
        this.setAction(true)
        this.setDiscard(true)
        this.setDiscardText('CREATE')
        this.getData(true)
        EventBus.$on('onClick', this.onEdit)
        EventBus.$on('onCancel', this.newQuotation)
        EventBus.$on('onPrint', this.onPrint);
    },
    destroyed () {
        this.setAction(false)
        EventBus.$off('onClick', this.onEdit)
        EventBus.$off('onCancel', this.newQuotation)
        EventBus.$off('onPrint', this.onPrint)
    },
    methods: {
        onTab (i) {
            this.tab = i
        },
        getData (v) {
            this.isLoading = true
            axios.get('/quotations/detail/'+this.$route.params.id)
            .then(res => {
                this.isLoading = false
                this.quotation = res.data.quotation
                this.activities = res.data.activities
                this.delivery = res.data.delivery
                if(v) {
                    this.pushBreadcrumb({
                        text: res.data.quotation.quotationNo
                    }) 
                }
            })
            .catch(err => {
                this.isLoading = false
                console.log(err);
            })
        },
        onEdit () {
            this.$router.push('/quotations/edit/'+ this.$route.params.id)
        },
        onCancelled () {
            for (let i=0; i < this.delivery.length; i++) {
                const el = this.delivery[i]
                if(el.status == 'Delivery' || el.status == 'Done') {
                    this.modalCancelSalesOrder = true
                    return
                }
            }
            axios.put('/quotations/cancel/'+this.$route.params.id)
            .then(() => {
                this.getData(false)
            })
        },
        onSetToQuotation () {
            axios.put('/quotations/settoquotation/'+this.$route.params.id)
            .then(() => {
                this.getData(false)
            })
        },
        onConfirm () {
            axios.put('/quotations/confirm/'+this.$route.params.id)
            .then((res) => {
                this.setBreadcrumbs([
                    {text: 'Sales Orders', path: '/sales'}
                ])
                this.$router.push('/sales/detail/'+res.data._id)
            })
        },
        newQuotation () {
            this.$router.push('/quotations/new')
        },
        getDate (d) {
            return moment(d)
        },
        onPrint () {
            let name;
            if(this.quotation.customer.parent) {
                name = this.quotation.customer.parent.name
            } else {
                name = this.quotation.customer.displayName
            }
            let title = this.quotation.quotationNo.replace(/\//g, ".") +" ("+name+")"
            document.title = title
            this.$nextTick(() => {
                printJS({
                    printable: 'printJS-form', 
                    type: 'html',
                    css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                    scanStyles: false,
                    documentTitle: 'dutapersada',
                    onPrintDialogClose: () => this.closePrint()
                })
            })
        },
        closePrint () {
            document.title = 'APP DUTAPERSADA'
        },
        onDelivery () {
            if(this.delivery.length > 1) {
                this.$router.push({path: '/delivery-orders', query: {salesId: this.quotation._id}})
            } else {
                this.$router.push({path: '/delivery-orders/detail/'+this.delivery[0]._id})
            }
        },
    },
    beforeRouteLeave (to, form, next) {
        if(to.name != 'Quotation Edit' && to.name != 'Sales detail') {
            this.replaceBreadcrumb({
                find: this.quotation.quotationNo,
                replace: {text: this.quotation.quotationNo, path: this.$route.fullPath}
            })
        }
        next()
    }
}
</script>