import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Setting from '../views/settings/index.vue'
import Company from '../views/settings/company.vue'
import Shipping from '../views/settings/shipping.vue'
import Bank from '../views/settings/bank.vue'
import UsersManagement from '../views/settings/users.vue'
import GeneralSetup from '../views/settings/general.vue'
import SupplierIndex from '../views/suppliers/index.vue'
import Suppliers from '../views/suppliers/suppliers.vue'
import SupplierNew from '../views/suppliers/supplierNew.vue'
import SupplierDtail from '../views/suppliers/supplierDetail.vue'
import SupplierEdit from '../views/suppliers/supplierEdit.vue'
import ProductIndex from '../views/products/index.vue'
import Products from '../views/products/products.vue'
import ProductNew from '../views/products/productNew.vue'
import ProductEdit from '../views/products/productEdit.vue'
import ProductDetail from '../views/products/productDetail.vue'
import Services from '../views/products/services.vue'
import Barcode from '../views/products/barcode.vue'
import Brands from '../views/products/brands.vue'
import Categories from '../views/products/categories.vue'
import SalesQuotationIndex from '../views/sales/quotations/index.vue'
import SalesQuotations from '../views/sales/quotations/quotations.vue'
import SalesQuotationNew from '../views/sales/quotations/quotationNew.vue'
import SalesQuotationDetail from '../views/sales/quotations/quotationDetail.vue'
import SalesQuotationEdit from '../views/sales/quotations/quotationEdit.vue'
import SalesOrderIndex from '../views/sales/order/index.vue'
import SalesOrder from '../views/sales/order/salesOrder.vue'
import SalesNew from '../views/sales/order/salesNew.vue'
import SalesDetail from '../views/sales/order/salesDetail.vue'
import SalseEdit from '../views/sales/order/salesEdit.vue'
import PurchaseIndex from '../views/purchases/index.vue'
import PurchaseOrder from '../views/purchases/purchaseOrder.vue'
import PurchaseNew from '../views/purchases/purchaseNew.vue'
import PurchaseDetail from '../views/purchases/purchaseDetail.vue'
import PurchaseEdit from '../views/purchases/purchaseEdit.vue'
import InventoryTransferIndex from '../views/inventory/transfer/index.vue'
import InventoryTransfer from '../views/inventory/transfer/transfers.vue'
import InventoryTransferNew from '../views/inventory/transfer/transferNew.vue'
import InventoryTransferDetail from '../views/inventory/transfer/transferDetail.vue'
import InventoryTransferEdit from '../views/inventory/transfer/transferEdit.vue'
import InventoryStockTakeIndex from '../views/inventory/stocktake/index.vue'
import InventoryStockTake from '../views/inventory/stocktake/stocktakes.vue'
import Login from '../views/auth/login'
import Eror404 from '../views/error/404.vue'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Dashboard',
		component: () => import('../views/dashboard/Dashboard.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/customers',
		component: () => import('../views/customers/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Customers',
				component: () => import('../views/customers/customers.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'new',
				name: 'New Customer',
				component: () => import('../views/customers/customerNew.vue'),
				meta: {
					auth: true,
				}
			},
			{
				path: 'detail/:id',
				name: 'Deatil',
				component: () => import('../views/customers/customerDetail.vue'),
				meta: {
					auth: true
				}
			},
			{
				path:'edit/:id',
				name: 'Edit Customer',
				component: () => import('../views/customers/customerEdit.vue'),
				meta: {
					auth: true
				}
			},
		]
	},
	{
		path: '/suppliers',
		component: SupplierIndex,
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Suppliers',
				component: Suppliers,
				meta: {
					auth: true
				}
			},
			{
				path: 'new',
				name: 'New Supplier',
				component: SupplierNew,
				meta: {
					auth: true
				}
			},
			{
				path: ':id',
				name: 'Detail Supplier',
				component: SupplierDtail,
				meta: {
					auth: true
				}
			},
			{
				path: 'edit/:id',
				name: 'Edit Supplier',
				component: SupplierEdit,
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/services',
		name: 'Service',
		component: Services,
		meta: {
			auth: true
		}
	},
	{
		path: '/barcode',
		name: 'Barcode',
		component: Barcode,
		meta: {
			auth: true
		}
	},
	{
		path: '/categories',
		name: 'Categoreis',
		component: Categories,
		meta: {
			auth: true
		}
	},
	{
		path: '/brands',
		name: 'Brands',
		component: Brands,
		meta: {
			auth: true
		}
	},
	{
		path: '/products',
		component: ProductIndex,
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Products',
				component: Products,
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				name: 'Create product',
				component: ProductNew,
				meta: {
					auth: true
				}
			},
			{
				path: 'edit/:productId',
				name:'Edit product',
				component: ProductEdit,
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:productId',
				name: 'Detail product',
				component: ProductDetail,
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/pos',
		component: () => import('../views/pos/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'POS',
				component: () => import('../views/pos/create.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'orders',
				name: 'POS Orders',
				component: () => import('../views/pos/orders.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/quotations',
		component: SalesQuotationIndex,
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Quotations',
				component: SalesQuotations,
				meta: {
					auth: true
				}
			},
			{
				path: 'new',
				name: 'New Quotation',
				component: SalesQuotationNew,
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				name: 'Quotation Detail',
				component: SalesQuotationDetail,
				meta : {
					auth: true
				}
			},
			{
				path: 'edit/:id',
				name: "Quotation Edit",
				component: SalesQuotationEdit,
				meta: {
					auth: true
				}
			},
		]
	},
	{
		path: '/sales',
		component: SalesOrderIndex,
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				component: SalesOrder,
				name: 'Sales',
				meta: {
					auth: true
				}
			},
			{
				path: 'new',
				component: SalesNew,
				name: 'New sales',
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				component: SalesDetail,
				name: 'Sales detail',
				meta: {
					auth: true
				}
			},
			{
				path: 'edit/:id',
				component: SalseEdit,
				name: 'Sales edit',
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/purchases',
		component: PurchaseIndex,
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Purchase Orders',
				component: PurchaseOrder,
				meta: {
					auth: true
				}
			},
			{
				path: 'new',
				name: 'New Pruchase',
				component: PurchaseNew,
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				name: 'Purchase detail',
				component: PurchaseDetail,
				meta: {
					auth: true
				}
			},
			{
				path: 'edit/:id',
				name: 'Purhchase edit',
				component: PurchaseEdit,
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/stock-transfers',
		component: InventoryTransferIndex,
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Stock Transfer',
				component: InventoryTransfer,
				meta: {
					auth: true
				}
			},
			{
				path: 'new',
				name: 'New Transfer',
				component: InventoryTransferNew,
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				name: 'Detail Transfer',
				component: InventoryTransferDetail,
				meta: {
					auth: true
				}
			},
			{
				path: 'edit/:id',
				name: 'Edit Transfer',
				component: InventoryTransferEdit,
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/receipts',
		component: () => import ('../views/inventory/receipts/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Receipts',
				component: () => import('../views/inventory/receipts/receipts.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				name: 'Receipts Detail',
				component: () => import('../views/inventory/receipts/detail.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/delivery-orders',
		component: () => import ('../views/inventory/delivery/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Delivery Orders',
				component: () => import('../views/inventory/delivery/delivery.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				component: () => import('../views/inventory/delivery/deliveryDetail.vue'),
				name: "Delivery Detail",
				meta: {
					auth: true
				}
			},
		]
	},
	{
		path: '/stock-takes',
		component: InventoryStockTakeIndex,
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Stock Takes',
				component: InventoryStockTake,
				meta: {
					auth: true
				}
			},
		]
	},
	{
		path: '/stock-opname',
		component: () => import('../views/inventory/stockopname/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Stock Opname',
				component: () => import('../views/inventory/stockopname/stockopname.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				name: 'Create Stock Opname',
				component: () => import('../views/inventory/stockopname/create.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				name: 'Detail Strock Opname',
				component: () => import ('../views/inventory/stockopname/detail.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/settings',
		component: Setting,
		meta: {
			auth: true,
		},
		children: [
			{
				path: '',
				name: 'Settings',
				component: Company,
				meta: {
					auth: true
				}
			},
			{
				path: 'shippings',
				name: 'Shippings',
				component: Shipping,
				meta: {
					auth: true
				}
			},
			{
				path: 'bank-accounts',
				name: 'Bank accounts',
				component: Bank,
				meta: {
					auth: true
				}
			},
			{
				path: 'user-management',
				name: 'User Management',
				component: UsersManagement,
				meta: {
					auth: true
				}
			},
			{
				path: 'general-setup',
				name: 'General Setup',
				component: GeneralSetup,
				meta: {
					auth: true
				}
			}

		]
	},
	{
		path: '/invoices',
		component: () => import ('../views/accounting/invoices/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				component: () => import('../views/accounting/invoices/invoices.vue'),
				name: 'Invoices',
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				component: () => import('../views/accounting/invoices/invoiceDetail.vue'),
				name: 'Invoice Detail',
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/bank',
		name: 'Bank',
		component: () => import('../views/accounting/bank/index.vue'),
		meta: {
			auth: true
		}
	},
	{
		path: '/webchat/staff',
		component: () => import ('../views/chat/chat.vue'),
		name: 'Webchat',
		meta: {
			auth: true
		}
	},
	{
		path: '/expenses',
		component: () => import('../views/expenses/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Expenses',
				component: () => import('../views/expenses/expenses.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				name: 'Create Expenses',
				component: () => import('../views/expenses/expensesCreate.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'detail/:id',
				name: 'Detail Expenses',
				component: () => import('../views/expenses/expensesDetail.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/serial-numbers',
		component: () => import('../views/serial/index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '',
				name: 'Serial Number',
				component: () => import('../views/serial/serial.vue'),
				meta: {
					auth: true
				}
			},
			{
				path: 'create',
				name: 'Create Serial Number',
				component: () => import('../views/serial/create.vue'),
				meta: {
					auth: true
				}
			}
		]
	},
	{
		path: '/login',
		component: Login,
		name: 'Login'
	},
	{
		path: '*',
		component: Eror404
	}
]

const router = new VueRouter({
	scrollBehavior () {
		return {x: 0, y: 0, behavior: 'smooth'}
	},
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	const tokenLocalStorage = localStorage.getItem('token')
	const userId = localStorage.getItem('userId')
	if(to.meta.auth && !store.getters['auth/token'] || to.meta.auth && !tokenLocalStorage || to.meta.auth && !userId) next({name: 'Login'})
	else if(!to.meta.auth && store.getters['auth/token'] && tokenLocalStorage && userId) next({name: 'Dashboard'})
	else next()
})

export default router
