<template>
    <div class="m-5">
        <div class="flex items-center justify-between mb-2">
            <div class="flex items-center w-3/5">
                <div class="form-feedback form-feedback-right w-full">
                    <input v-model="search" ref="search"  type="text" class="form-control" placeholder="search purchase order or supplier">
                    <div v-show="!search" class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-400"></i>
                        </span>
                    </div>
                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search = ''" href="#">
                            <i class="icon-cancel-circle2 text-red-500"></i>
                        </a>
                    </div>
                    <div v-show="filters.length > 0" ref="filterSearch" class="absolute inset-y-0 my-1 left-1 flex items-center rounded-l-sm overflow-hidden bg-white">
                        <div class="bg-gray-700 h-full flex items-center text-gray-100 px-1"><i class="icon-filter3" style="font-size: 12px"></i></div>
                        <div class="border border-l-0 border-gray-400 h-full flex items-center px-1">
                            <ul class="flex space-x-1">
                                <li v-for="(filter, i) in filters" :key="i">{{filter}}
                                    <span v-show="filters.length > 1 && filters.length > i+1" class="italic">or</span>
                                </li>
                                <li class="flex items-center">
                                    <a @click="clearFilter" href="#" class="flex hover:text-red-500"><i class="icon-cross3"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div ref="filter" class="ml-2 relative">
                    <button @click="showFilter = !showFilter" type="button" class="bg-white border whitespace-nowrap relative rounded-sm h-9 w-full focus:outline-none hover:bg-gray-50">
                        <div class="relative h-full flex items-center">
                            <div class="h-full w-9 bg-gray-200 rounded-l-sm flex justify-center items-center">
                                <i class="icon-filter3"></i>
                            </div>
                            <div class="pl-3">
                                <span class="text-gray-500 pr-7">Filter</span>
                            </div>
                            <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                <i class="icon-arrow-down22"></i>
                            </div>
                        </div>
                    </button>
                    <div v-show="showFilter" class="absolute bg-white border mt-0.5 w-full">
                        <ul class="my-1">
                            <li v-for="(filter, i) in filterStatus" :key="i" class="hover:bg-gray-100">
                                <a @click="filter.status = !filter.status" href="#" class="flex items-center py-1 pl-1 pr-3">
                                    <div class="flex w-4">
                                        <i v-show="filter.status" class="icon-checkmark2 text-teal-500"></i>
                                    </div>
                                    <div class="ml-1" :class="{'font-medium': filter.status}">{{filter.text}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
               <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <button class="h-7 w-7 bg-gray-100 border  -mr-px"><i class="icon-grid6"></i></button>
                    <button class="h-7 w-7 bg-gray-100 border"><i class="icon-list2"></i></button>
                </div>
            </div>
        </div>

        <div class="rounded-sm overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr class="bg-cyan-600 border border-cyan-600">
                        <th class="py-2 pl-5 pr-2 text-cyan-100" style="width: 15%">PURCHASE ORDER #</th>
                        <th class="py-2 px-5 text-cyan-100" style="width: 25%">SUPPLIER</th>
                        <th class="py-2 px-5 text-cyan-100" style="width: 10%">RECEIVE</th>
                        <th class="py-2 px-5 text-cyan-100" style="width: 10%">PAYMENT</th>
                        <th class="py-2 px-5 text-cyan-100 text-right" style="width: 15%">TOTAL</th>
                        <th class="py-2 px-5 text-cyan-100 text-center" style="width: 10%">STATUS</th>
                        <th class="py-2 px-5 text-cyan-100" style="width: 15%">DATE CREATED</th>
                    </tr>
                </thead>
                <tbody v-if="!isLoading" class="bg-white">
                   <tr v-for="(prc, i) in purchases" :key="i" class="border">
                        <td class="py-2 px-5">
                            <router-link :to="'/purchases/detail/'+prc._id" class="text-cyan-600 flex items-center hover:text-cyan-700">{{prc.no}}</router-link>
                        </td>
                        <td class="py-2 px-5">
                            <div class="turncate">{{prc.supplier}}</div>
                        </td>
                        <td class="py-2 px-5">
                            <div v-if="prc.receiveStatus == 'Nothing to Receive'" class="px-1.5 py-px text-xs w-max rounded-full bg-gray-300 text-gray-600">{{prc.receiveStatus}}</div>
                            <div v-if="prc.receiveStatus == 'Partial'" class="px-1.5 py-px text-xs w-max rounded-full bg-orange-500 text-gray-50">{{prc.receiveStatus}}</div>
                            <div v-if="prc.receiveStatus == 'In Progress'" class="px-1.5 py-px text-xs w-max rounded-full bg-gray-500 text-gray-50">{{prc.receiveStatus}}</div>
                            <div v-if="prc.receiveStatus == 'Done'" class="px-1.5 py-px w-max text-xs text-orange-50 rounded-full bg-green-500">{{prc.receiveStatus}}</div>
                        </td>
                        <td class="py-2 px-5">
                            <div class="flex items-center">
                                <div v-if="prc.billingStatus == 'Nothing to Bill'" class="px-1.5 py-px w-max text-xs text-gray-50 rounded-full bg-gray-500">{{prc.billingStatus}}</div>
                                <div v-if="prc.billingStatus == 'Waiting Bills'" class="px-1.5 py-px w-max text-xs text-gray-50 rounded-full bg-blue-500">{{prc.billingStatus}}</div>
                                <div v-if="prc.billingStatus == 'Partial Paid'" class="px-1.5 py-px w-max text-xs rounded-full text-blue-50 bg-green-600">{{prc.billingStatus}}</div>
                                <div v-if="prc.billingStatus == 'Paid'" class="px-1.5 py-px w-max text-xs rounded-full text-cyan-50 bg-green-600">{{prc.billingStatus}}</div>
                            </div>
                        </td>
                         <td class="py-2 px-5 text-right font-medium">
                            <span v-if="prc.currencyId">{{prc.currencySymbol}}</span>{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(prc.grandTotal)}}
                        </td>
                        <td class="py-2 px-5 flex justify-center font-medium">
                            <div class="flex items-center">
                                <div v-if="prc.status == 'RFQ'" class="px-1.5 py-px w-max text-xs text-gray-50 rounded-full bg-gray-500">{{prc.status}}</div>
                                <div v-if="prc.status == 'RFQ Sent'" class="px-1.5 py-px w-max text-xs rounded-full text-blue-50 bg-blue-800">{{prc.status}}</div>
                                <div v-if="prc.status == 'Purchase Order'" class="px-1.5 py-px w-max text-xs rounded-full text-cyan-50 bg-cyan-500">{{prc.status}}</div>
                                <div v-if="prc.status == 'Cancelled'" class="px-1.5 py-px w-max text-xs text-red-50 rounded-full bg-red-500">{{prc.status}}</div>
                            </div>
                        </td>
                        <td class="py-2 px-5">{{getDate(prc.createdAt)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5 text-right">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import paginate from '../../components/paginate.vue'
import {moment} from '../../moment'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
import debounce from 'lodash.debounce'
import {EventBus} from '../../event-bus'
export default {
    components: {paginate},
    mixins: [BreadcrumbManager],
    data () {
        return {
            isLoading: false,
            showFilter: false,
            filterStatus: [
                {status: false, text: 'RFQ'},
                {status: false, text: 'RFQ Sent'},
                {status: false, text: 'Purchase Order'},
                {status: false, text: 'Cancelled'},
            ],
            search: '',
            purchases: [],
            pages: '',
            searchData: ''
        }
    },
    created () {
        this.searchData = debounce(() => {
            this.getData()
        }, 300)
    },
    mounted () {
        this.setButtonText('CREATE')
        this.setDiscard(false)
        this.setBreadcrumbs([
            {text: 'Purchase Orders'}
        ])
        this.getData()
        document.addEventListener('click', this.closeFilter)
        EventBus.$on('onClick', this.onCreate)
    },
    destroyed () {
        EventBus.$off('onClick', this.onCreate)
        document.removeEventListener('click', this.closeFilter)
    },
    computed: {
        filters () {
            let data = []
            for (let i=0; i<this.filterStatus.length; i++) {
                if (this.filterStatus[i].status == true) {
                    data.push(this.filterStatus[i].text)
                }
            }
            return data
        }
    },
    methods: {
        getData (e) {
            this.isLoading = true
            window.scrollTo(0, 0)
            axios.get('/purchases', {
                params: {
                    search: this.search,
                    filters: this.filters,
                    page: e
                }
            })
            .then(res => {
                this.isLoading = false
                this.purchases = res.data.data
                this.pages = res.data.pages
            })
        },
        onCreate () {
            this.$router.push('/purchases/new')
        },
        getDate (d) {
            return moment(d)
        },
        clearFilter () {
            for (let i=0; i < this.filterStatus.length; i++) {
                this.filterStatus[i].status = false
            }
        },
        closeFilter (e) {
            if(!e || !this.$refs.filter.contains(e.target)) {
                this.showFilter = false
            }
        }
    },
    watch: {
        search () {
            this.searchData()
        },
        filterStatus: {
            handler: function () {
                this.$nextTick(() => {
                    let w = this.$refs.filterSearch.clientWidth + 11
                    this.$refs.search.style.paddingLeft = w+'px'
                    this.getData()
                })
            },  
            deep: true
        }
    }
}
</script>

<style scoped>
    .text-xs {
        font-size: 10px !important;
    }
</style>