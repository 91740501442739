<template>
    <div>
        <div class="flex items-center justify-between bg-white border rounded-t-sm px-4 shadow-lg">
            <div>
                <stepper>
                    <li><button class="active">QUOTATION</button></li>
                    <li><button>QUOTATION SENT</button></li>
                    <li><button>SALES ORDER</button></li>
                </stepper>
            </div>
            <div>
                <button @click="onConfirm" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100 relative" :disabled="isDisabled">
                    <div v-if="isDisabled" class="absolute bg-teal-400 bg-opacity-70 w-full h-full inset-0 rounded-sm flex items-center justify-center">
                        <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                    CONFIRM
                </button>
                <button class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">SEND BY EMAIL</button>
            </div>
        </div>
        <div class="m-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-red-500 hover:border-gray-200 hover:text-red-600">INFO</div>
                    </div>
                </div>
            </div>
            <div class="border border-t-0 border-b-0 p-5 bg-white">
                <div class="grid grid-cols-9 divide-x">
                    <div class="col-span-5 pr-5">
                        <div class="grid grid-cols-2 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">New</div>
                                <div class="text-gray-400 text-xs">QUOTATION #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg">--</div>
                                <div class="text-gray-400 text-xs">DATE CREATED</div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="">CUSTOMER <span class="text-red-500">*</span></label>
                            <div class="mt-2 flex items-center w-full">
                                <autocomplete class="w-full text-lg" ref="autocomplete" @onSearchMore="modalCustomerSearch = true" @onEdit="onEditCreateCustomer($event)" :err="errCustomerId" v-model="customer" :label="customer.displayName" @input="onSelectCustomer($event)" :text="true" :url="'/customers/auto-search'" :placeholder="'Search customer by name or code'">
                                    <template #default="item">
                                        <div>
                                            <span >{{item.item.displayName}}</span>
                                            <div class="text-gray-400 text-xs">
                                                <span v-show="item.item.contact.mobile">{{item.item.contact.mobile}}</span>
                                                <span :class="{'ml-3': item.item.contact.mobile}" v-show="item.item.address">{{item.item.address.street}}</span>
                                            </div>
                                        </div>
                                    </template>
                                </autocomplete>
                                <button @click="onEditCustomer(customer)" tabindex="-1" v-show="customer" class="text-gray-400 hover:text-teal-500 focus:outline-none"><i class="icon-pencil7 ml-2"></i></button>
                            </div>
                            <div v-if="customer">
                                <div>{{customer.address.street}}</div>
                                <div>{{customer.address.street2}}</div>
                                <div>{{customer.address.subdistrictName}} {{customer.address.cityName}} {{customer.address.zip}}</div>
                                <div>{{customer.address.provinceName}}</div>
                            </div>
                        </div>
                        <div class="mb-5">
                            <label for="">CUSTOMER REFERENCE</label>
                            <input v-model="form.customerReference" type="text" class="form-control mt-2">
                        </div>
                        <div class="mb-4">
                            <div class="flex items-center justify-between">
                                <div>
                                    <label for="">REMARKS</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <input id="urgent" v-model="form.remarks.urgent" type="checkbox">
                                    <label for="urgent">Urgent</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <input id="foryourreview" v-model="form.remarks.forYourReview" type="checkbox">
                                    <label for="foryourreview">For Your Review</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <input id="replyasap" v-model="form.remarks.replyAsap" type="checkbox">
                                    <label for="replyasap">Reply ASAP</label>
                                </div>
                                <div class="form-radio flex justify-center">
                                    <input id="pleasecomment" v-model="form.remarks.pleaseComent" type="checkbox">
                                    <label for="pleasecomment">Please Comment</label>
                                </div>
                            </div>
                            <textarea v-model="form.remarks.comment" placeholder="remarks" rows="1" class="border w-full rounded-sm p-3 mt-2 focus:outline-none"></textarea>
                        </div>
                        <div>
                            <label for="">TAGS</label>
                            <tags-input v-model="form.tags" class="mt-2"></tags-input>
                        </div>
                    </div>
                    <div class="col-span-4 pl-5">
                        <div class="grid grid-cols-2 gap-5 mb-5">
                            <div>
                                <label for="">ESTIMATED DELIVERY TIME</label>
                                <div class="mt-2">
                                    <div class="flex">
                                        <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-calendar3"></i></span>
                                        <input v-model="form.estimatedDeliveryTime" type="text" class="form-control rounded-tl-none rounded-bl-none border-l-0 text-blue-500">
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label for="">DATE VALIDATY</label>
                                <div class="mt-2">
                                    <date-picker v-model="form.dateValidaty" :masks="masks" class="mt-2">
                                        <template v-slot="{inputValue, inputEvents}">
                                            <div class="flex">
                                                <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-calendar3"></i></span>
                                                <input :value="inputValue" v-on="inputEvents" type="text" class="form-control rounded-tl-none rounded-bl-none border-l-0 text-blue-500">
                                            </div>
                                        </template>
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="mb-2">
                                <label for="">ADDITIONAL CHARGES</label>
                            </div>
                            <div v-for="(charges, i) in form.additionalCharges" :key="i" class="grid grid-cols-2 gap-5 text-teal-500">
                                <div class="slt charges">
                                    <select-2 :ref="'additionalCharges'+i" v-model="charges.id" @input="onSelectAdditionalCharges($event, i)" :options="additionalChargesList[i]" :placeholder="'Please select addtional charges'" class="form-control bg-gray-200">
                                        <option value=""></option>
                                    </select-2>
                                </div>
                                <div>
                                    <div v-if="charges.isFixed" class="flex items-center">
                                        <autonumeric v-model="charges.unitPrice" :options="{currencySymbol: 'Rp', modifyValueOnWheel : false}" class="form-control border-t-0 border-l-0 border-r-0 text-right" :placeholder="'unit price'"></autonumeric>
                                        <a @click.prevent="deleteAdditionalCharges(i)" href="#" tabindex="-1" class="ml-2 text-red-400"><i class="icon-cancel-circle2"></i></a>
                                    </div>
                                    <div v-else class="flex items-center">
                                        <autonumeric v-model="charges.unitPrice" :options="['integer',{suffixText: '%', modifyValueOnWheel : false}]" class="form-control border-t-0 border-l-0 border-r-0 text-right" :placeholder="'unit price'"></autonumeric>
                                        <a @click.prevent="deleteAdditionalCharges(i)" href="#" tabindex="-1" class="ml-2 text-red-400"><i class="icon-cancel-circle2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div v-if="additionalCharges.length > additionalChargesList.length" class="grid grid-cols-2 gap-5 mt-1">
                                <div class="slt">
                                    <div class="w-full bg-gray-100 border-b h-7 rounded-sm"></div>
                                </div>
                                <div>
                                    <div class="flex items-center">
                                        <div class="w-full bg-gray-100 border-b h-7 rounded-sm"></div>
                                        <a @click.prevent="addAddtionalCharges" tabindex="-1" href="#" class="ml-2 text-green-500"><i class="icon-plus-circle2"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <add-item v-model="form.items">
                <!-- DISCOUNT -->
                <tr v-if="form.discount.value" class="font-medium text-red-500">
                    <td colspan="6" class="px-2 py-1 text-right">Discount on total price</td>
                    <td class="px-2 py-1 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.discount.value)}}</td>
                    <td></td>
                </tr>
                <!-- TAX -->
                <tr class="font-medium text-blue-500">
                    <td colspan="6" class="px-2 h-7 text-right">
                        <div class="flex items-center justify-end">
                            <select-tax @input="onSelectTax($event)" :options="taxCodes" class="ml-3 w-32 -m-1.5"></select-tax>
                        </div>
                    </td>
                    <td class="px-2 h-5 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(tax)}}</td>
                    <td></td>
                </tr>
                <!-- ADDITIONAL CHARGES -->
                <tr v-if="totalAdditionalCharges" class="font-medium text-green-600">
                    <td colspan="6" class="px-2 h-7 text-right">ADDITIONAL CHARGERS	</td>
                    <td class="px-2 h-7 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(totalAdditionalCharges)}}</td>
                    <td></td>
                </tr>
                <!-- SHIPPING -->
                <tr class="font-medium">
                    <td colspan="6" class="px-2 h-7 text-right">
                        <button @click="modalShipping=true" class="hover:bg-gray-100 px-2 rounded-sm relative -mr-1.5">
                            <span v-if="form.shipping.shipmentCost" class="mr-5">
                                UPDATE SHPMENT COST
                                <a @click.prevent.stop="onClearShpping" href="#" class="absolute right-0.5 hover:text-red-500"><i class="icon-cross3"></i></a>
                            </span>
                            <span v-else>ADD SHIPPING</span>
                        </button>
                    </td>
                    <td class="px-2 h-7 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.shipping.shipmentCost)}}</td>
                    <td></td>
                </tr>
                <!-- TOTAL -->
                <tr class="bg-orange-100">
                    <td></td>
                    <td></td>
                    <td colspan="3" class="py-1 pl-10 pr-2.5">
                        <vue-discount ref="discount" :idx="'discount'" v-model="form.discount" :total="total" :symbol="'Rp'" :label="'Discount on Total Price'"></vue-discount>
                    </td>
                    <td class="text-right text-base font-semibold">TOTAL</td>
                    <td class="text-right text-base font-semibold px-2">Rp{{Intl.NumberFormat('en-US', {minimumFractionDigits:2}).format(grandTotal)}}</td>
                    <td></td>
                </tr>
            </add-item>
            <!-- KONDISI PENAWARAN -->
            <div class="p-5 bg-white border border-t-0 rounded-b-sm">
                <div class="text-base font-medium">KONDISI PENAWARAN</div>
                <div v-for="(condition, i) in form.offerConditions" :key="i" class="flex items-center">
                    <input :ref="'condition'+i" v-model="condition.name" class="h-8 mr-5 w-36 border-b focus:outline-none pl-0 rounded-none border-l-0 border-t-0 border-r-0" type="text">
                    <input v-model="condition.description" type="text" class="h-8 border-b focus: outline-none w-2/4 rounded-none border-t-0 border-l-0 border-r-0">
                    <a @click.prevent="onDeleteOfferCondition(i)" href="#" class="h7 w-7 flex items-center justify-center text-red-500 hover:text-red-600"><i class="icon-cancel-circle2"></i></a>
                </div>
                <div class="flex items-center mt-2">
                    <div class="w-36 mr-5 bg-gray-100 h-7"></div>
                    <div class="w-2/4 bg-gray-100 h-7"></div>
                    <div class="bg-gray-100 rounded-r-sm overflow-hidden">
                        <a @click.prevent="onAddOfferCondition" href="#" class="h-7 w-7 border-l border-gray-200 flex items-center justify-center text-green-500 hover:text-green-600"><i class="icon-plus-circle2"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <modal-customer v-model="customer" @input="onSelectCustomer($event)" :show="modalCustomer" :dataCustomer="dataCustomer" :title="modalTitle" @onEditParent="onEditParent($event)" @onClose="modalCustomer = false"></modal-customer>
        <div v-if="customer.parents">
            <div v-for="(parent, i) in customer.parents" :key="i">
                <modal-customer v-model="dataParentEdit" :dataCustomer="dataParent[i]" :title="'EDIT CUSTOMER'" :show="modalParent[i].modal" @onEditParent="onEditParent($event)" @onClose="modalParent[i].modal=false"></modal-customer>
            </div>
        </div>
        
        <modal-customer-search v-model="customer" @input="onSelectCustomer($event)" :show="modalCustomerSearch" @closeModal="modalCustomerSearch = false"></modal-customer-search>
        
        <shipping v-model="form.shipping" :show="modalShipping" @closeModal="modalShipping = false"></shipping>
        <!-- ALERT -->
        <div v-show="invalids.length > 0" class="fixed bg-red-200 z-50 animated fadeInDown faster top-5 w-72 right-5 text-black p-2 rounded-sm shadow-lg">
            <div class="font-medium flex justify-between items-center">
                <div>Invalid Fields:</div>
                <a @click.prevent="closeAlert" href="#" class="hover:text-red-600"><i class="icon-cross3"></i></a>
            </div>
            <div class="pl-5">
                <ul class="list-disc">
                    <li v-for="(invalid, i) in invalids" :key="i">{{invalid.text}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import stepper from '../../../components/stepper.vue'
import autocomplete from '../../../components/Autocomplete.vue'
import TagsInput from '../../../components/TagsInput.vue' 
import Select2 from '../../../components/Select2.vue'
import autonumeric from '../../../components/Autonumeric.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import VueDiscount from '../../../components/discount.vue'
import axios from 'axios'
import ModalCustomer from '../../../components/modalCustomer.vue'
import ModalCustomerSearch from '../../../components/modalCustomerSearch.vue'
import {EventBus} from '../../../event-bus'
import BreadcrumbManager from '../../../mixins/breadcrumbsManager.vue'
import AddItem from '../../../components/addItems.vue'
import SelectTax from '../../../components/tax.vue'
import Shipping from '../../../components/shipping.vue'
export default {
    components: {stepper, autocomplete, TagsInput, Select2, autonumeric, DatePicker, VueDiscount, ModalCustomer, AddItem, SelectTax, ModalCustomerSearch, Shipping},
    mixins: [BreadcrumbManager],
    data () {
        return {
            isDisabled: false,
            modalShipping: false,
            modalCustomer: false,
            modalCustomerSearch: false,
            modalTitle: '',
            dataCustomer: '',
            modalParent: [],
            dataParent: [],
            dataParentEdit: '',
            imgURL: process.env.VUE_APP_ROOT_API,
            masks: {
                input: 'DD/MM/YYYY'
            },  
            customer: '',
            additionalCharges: [],
            additionalChargesList: [],
            taxCodes: [],
            showStock: false,
            form: {
                customerId: '',
                customerReference: '',
                remarks: {
                    comment: '',
                    urgent: false,
                    forYourReview: false,
                    replyAsap: false,
                    pleaseComment: false
                },
                tags: [],
                estimatedDeliveryTime: '30 Hari',
                dateValidaty: '',
                status: 'Quotation',
                additionalCharges: [],
                items: [{idx: Date.now(), productId: '', name: '', sellingPrice:'', unitPrice: '', stock: '', qty: '', delivered: 0, invoiced: 0 , total: '', discount: {discountType: '', amount: '', value: ''}, subTotal: '', hover: false, edited: false}],
                totalQty: '',
                total: '',
                totalAdditionalCharges: '',
                discount: {
                    discountType: '',
                    amount: '',
                    value: ''
                },
                tax: {
                    code: '',
                    amount: '',
                    value: ''
                },
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: '',
                    bookingCode: '',
                    trackingNumber: ''
                },
                grandTotal: '',
                offerConditions: [
                    {name: 'HARGA', description: 'Netto, Franco Bandung, dalam mata uang Rupiah'},
                    {name: 'PEMABAYARAN', description: 'DP 50% dan sisanya dilunasi pada saat barang siap di kirim ke pembeli'},
                    {name: 'DELIVERY TIME', description: '4,5 bulan sejak diterimanya uang muka dan PO'},
                    {name: 'VALIDITY', description: '30 hari dan selama JPY tidak lebih dari Rp 140'},
                    {name: 'PEMBATALAN', description: 'Tidak dapat dilakukan pembatalan setelah kami menerima PO atau Uang Muka'}
                ]
            },
            errCustomerId: '',
            invalids: [],
            invalidTimeout: ''
        }
    },
    mounted () {
        EventBus.$on('onClick', this.onSave)
        EventBus.$on('onCancel', this.onCancel)
        this.setButtonText('SAVE')
        this.setDiscard(true)
        this.setAction(false)
        this.setDiscardText('DISCARD')
        this.setBreadcrumbs([
            {text: 'Quotations', path: '/quotations'},
            {text: 'New'}
        ])
        this.$refs.autocomplete.setFocus()
        this.getData()
        this.validaty()
    },
    destroyed () {
        EventBus.$off('onClick', this.onSave)
        EventBus.$off('onCancel', this.onCancel)
    },
    computed: {
        productIds () {
            let id = []
            for (let i = 0; i < this.form.items.length; i++) {
                id.push(this.form.items[i].productId)
            }
            return id
        },
        items () {
            return this.form.items.map(obj => {
                obj.total = obj.unitPrice * obj.qty
                obj.subTotal = obj.total - obj.discount.value
                return obj
            })
        },
        totalAdditionalCharges () {
            let total = 0;
            for (let i = 0; i < this.form.additionalCharges.length; i++) {
                const el = this.form.additionalCharges[i]
                if(el.isFixed) {
                    total += el.unitPrice
                } else {
                    total += this.total * el.unitPrice / 100
                }
            }
            return total
        },
        total () {
            let total = 0
            for (let i = 0; i < this.form.items.length; i++) {
                total += this.form.items[i].subTotal
            }
            return total
        },
        tax () {
            let t = 0
            if(this.form.tax.amount) {
                t = (this.total - this.form.discount.value) * this.form.tax.amount / 100
            }
            return t
        },
        grandTotal () {
            let g = 0
            g = this.total + this.tax + this.totalAdditionalCharges + this.form.shipping.shipmentCost - this.form.discount.value
            return g
        },
        formSubmit () {
            for (let i in this.form) {
                if(this.form[i] === '') {
                    delete this.form[i]
                }
            }
            return this.form
        }
    },
    methods: {
        getData () {
            axios.get('/quotations/create')
            .then(res => {
                this.additionalCharges = res.data.additionalCharges
                this.taxCodes = res.data.taxCodes
            })
        },
        // CUSTOMER FUNCTION
        onSelectCustomer (val) {
            this.form.customerId = val._id
            this.errCustomerId = ''
            this.modalCustomerSearch = false
        },

        // DATE VALIDATY
        validaty () {
            let date = new Date()
            this.form.dateValidaty = date.setDate(date.getDate() + 30)
        },

        // ITEM FUNCTIONS
        onSave () {
            this.form.status = 'Quotation'
            this.onSubmit()
        },
        onConfirm () {
            this.isDisable = true
            if(!this.form.customerId) {
                this.errCustomerId = true
                window.scrollTo(0,0)
                this.invalids.push({text: 'Customer'})
            }
            if(this.form.items.length > 1) {
                for(let i=0; i < this.form.items.length; i++) {
                    if(!this.form.items[i].productId) {
                        this.form.items.splice(i, 1)
                    }
                }
            }
            for(let i=0; i<this.form.items.length; i++) {
                this.form.items[i].edited = false
                if(!this.form.items[i].productId) {
                    this.invalids.push({text: 'Items'})
                }
            }
            if(this.form.customerId && this.form.items.length > 0) {
                this.form.totalQty = this.totalQty
                this.form.total = this.total
                this.form.totalAdditionalCharges = this.totalAdditionalCharges
                this.form.tax.value = this.tax
                this.form.grandTotal = this.grandTotal
                axios.post('/quotations/create', this.formSubmit)
                .then(res => {
                    const id = res.data._id
                    axios.put('/quotations/confirm/'+id)
                    .then((res) => {
                        this.setBreadcrumbs([
                            {text: 'Sales Orders', path: '/sales'},
                            {text: 'New'}
                        ])
                        this.$router.push('/sales/detail/'+res.data._id)
                    })
                })
                .catch(err => {
                    this.isDisabled = false
                    console.log(err.response);
                })
            } else {
                this.isDisabled = false
            }
        },
        onSubmit () {
            this.setDisabled(true)
            if(!this.form.customerId) {
                this.errCustomerId = true
                window.scrollTo(0,0)
                this.invalids.push({text: 'Customer'})
            }
            if(this.form.items.length > 1) {
                for(let i=0; i < this.form.items.length; i++) {
                    if(!this.form.items[i].productId) {
                        this.form.items.splice(i, 1)
                    }
                }
            }
            for(let i=0; i<this.form.items.length; i++) {
                this.form.items[i].edited = false
                if(!this.form.items[i].productId) {
                    this.invalids.push({text: 'Items'})
                }
            }
            if(this.form.customerId && this.form.items.length > 0) {
                this.form.totalQty = this.totalQty
                this.form.total = this.total
                this.form.totalAdditionalCharges = this.totalAdditionalCharges
                this.form.tax.value = this.tax
                this.form.grandTotal = this.grandTotal
                axios.post('/quotations/create', this.formSubmit)
                .then(res => {
                    const id = res.data._id
                    this.setDisabled(false)
                    if(res.data.status == 'Sales Order') {
                        this.$router.push('/quotations/edit/'+id)
                    } else {
                        this.$router.push('/quotations/detail/'+id)
                    }
                })
                .catch(err => {
                    this.setDisabled(false)
                    console.log(err.response);
                })
            } else {
                this.setDisabled(false)
            }
        },

        closeAlert () {
            this.invalids = []
            clearTimeout(this.invalidTimeout)
        },

        // CHARGES FUNCTIONS
        addAddtionalCharges () {
            this.form.additionalCharges.push({id: '', isFixed: true, name: '', unitPrice: ''})
            const charges = this.additionalCharges.filter(obj1 => {
                return !this.form.additionalCharges.some(obj2 => {
                    return obj1.id === obj2.id
                })
            })
            this.additionalChargesList.push(charges)
            this.$nextTick(() => {
                const i = this.form.additionalCharges.length -1
                this.$refs['additionalCharges'+i][0].open()
            })
        },
        deleteAdditionalCharges (i) {
            this.form.additionalCharges.splice(i, 1)
            this.additionalChargesList.splice(i, 1)
            this.$nextTick(() => {
                for(let i = 0; i < this.form.additionalCharges.length; i++) {
                    this.$refs['additionalCharges'+i][0].update()
                }
            })
        },
        onSelectAdditionalCharges (e, i) {
            const charges = this.additionalChargesList[i].find(obj => obj.id === e)
            this.form.additionalCharges[i].name = charges.name
            this.form.additionalCharges[i].unitPrice = charges.unitPrice
            this.form.additionalCharges[i].isFixed = charges.isFixed
        },

        // TAX FUNCTION
        onSelectTax (e) {
            this.form.tax.code = e.code
            this.form.tax.amount = e.amount
        },
        onDeleteTax () {
            this.form.tax.code = ''
            this.form.tax.amount = ''
        },
        
        // SHIPPING FUNCTION
        onClearShpping () {
            this.form.shipping = {
                shipmentMethodId: '',
                shipmentService: '',
                shipmentCost: '',
            }
        },
        
        // OFFER CONDITION FUNCTION
        onAddOfferCondition () {
            this.form.offerConditions.push({name: '', description: ''})
            const i = this.form.offerConditions.length-1
            this.$nextTick(() => {
                this.$refs['condition'+i][0].focus()
                window.scrollTo(0, document.body.scrollHeight)
            })
        },
        onDeleteOfferCondition (i) {
            this.form.offerConditions.splice(i, 1)
        },

        // EDIT & CREATE CUSTOMER
        onEditCreateCustomer (e) {
            this.dataCustomer = e
            this.modalCustomer = true
            this.modalTitle = 'CREATE CUSTOMER'
        },
        onEditCustomer (e) {
            axios.get('/customers/edit/'+e._id)
            .then(res => {
                this.modalTitle = 'EDIT CUSTOMER'
                this.dataCustomer = res.data
                this.modalCustomer = true
            })
        },
        onEditParent (param) {
            let index = this.customer.parents.length - (param.i +1)
            axios.get('/customers/edit/'+param.id)
            .then(res => {
                this.dataParent[index] = res.data
                this.modalParent[index].modal = true
            })
        },
        onCancel () {
            this.$router.push('/quotations')
        }
        
    },
    watch: {
        dataParentEdit (val) {
            for(let i=0; i < this.dataParent.length; i++) {
                const el = this.dataParent[i]
                if(el.parents) {
                    let index = el.parents.findIndex(x => x._id == val._id)
                    if(el.parents[index]) {
                        el.parents[index].name = val.name
                    }
                }
            }
            let index = this.dataCustomer.parents.findIndex(x => x._id == val._id)
            this.dataCustomer.parents[index].name = val.name
        },
        customer (val) {
            if(val.parents) {
                this.modalParent = []
                this.dataParent = []
                for (let i = 0; i < val.parents.length; i++) {
                    this.modalParent.push({modal: false})
                    this.dataParent.push('')
                }
            }
        },
        invalids () {
            clearTimeout(this.invalidTimeout)
            this.invalidTimeout = setTimeout(this.closeAlert, 3500)
        }
    },
    beforeRouteLeave(to, from, next) {
        this.popBreadcrumb()
        next()
    }
}
</script>

<style >
    .charges .select2-selection__rendered {
        color: #14b8a6 !important;
    }
    
</style>